import { createRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import { Button, CssBaseline, StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import jwtDecode from "jwt-decode";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import { BeaconWallet } from "@taquito/beacon-wallet";
import {
  BeaconEvent,
  ColorMode,
  defaultEventCallbacks,
} from "@airgap/beacon-sdk";

import routes from "./routes";
import themes from "./themes";
import store from "./store/store";
import ShowSnack from "./components/common/snacks/ShowSnack";
import ErrorWarning from "./components/common/snacks/ErrorWarning";
import setAuthToken from "./utils/setAuthToken";
import {
  getUserInfo,
  loginUser,
  logoutUser,
  setCurrentUser,
  setLogoutTimer,
} from "./store/actions/auth";
import { setWallet, _walletConfig } from "./store/actions/tezos";
import beaconNetworkType from "./utils/beaconNetworkType";
import config from "./config";

if (localStorage.getItem("jwtToken")) {
  setAuthToken(localStorage.getItem("jwtToken"));
  const decoded = jwtDecode(localStorage.getItem("jwtToken"));

  store.dispatch(getUserInfo());
  store.dispatch(setCurrentUser(decoded));

  // Confirma se o token está válido
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());

    // Redirect para página de login
    window.location.href = "/login";
  }
}

const App = () => {
  const customization = useSelector((state) => state.customization);
  const { isAuthenticated, logoutTimer } = useSelector((state) => state.auth);

  const { Tezos } = useSelector((state) => state.tezos);

  const routing = useRoutes(routes(isAuthenticated));

  const notistackRef = createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      const jwtToken = localStorage.getItem("jwtToken");
      const jwtExpires = localStorage.getItem("jwtExpires");

      if (jwtToken && jwtExpires) {
        const currentTime = new Date().getTime();

        const expires = jwtExpires - currentTime;

        const logoutTimer = setTimeout(() => {
          dispatch(logoutUser());
        }, expires);

        dispatch(setLogoutTimer(logoutTimer));
      } else {
        dispatch(logoutUser());
        clearTimeout(logoutTimer);
      }
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    (async () => {
      const wallet_instance = new BeaconWallet({
        name: "Festival de Cannes",
        iconUrl: "https://tezostaquito.io/img/favicon.png",
        appUrl: "https://tickets-demo.skillmind.pt",
        preferredNetwork: beaconNetworkType,
        colorMode: ColorMode.LIGHT,
        disableDefaultEvents: false, // Disable all events / UI. This also disables the pairing alert.
        eventHandlers: {
          // To keep the pairing alert, we have to add the following default event handlers back
          [BeaconEvent.PAIR_INIT]: {
            handler: defaultEventCallbacks.PAIR_INIT,
          },
          // [BeaconEvent.PAIR_SUCCESS]: {
          //   handler: (data) => {
          //     return data.publicKey;
          //   },
          // },
          // PERMISSION_REQUEST_SUCCESS: {
          //   handler: async (data) => {
          //     console.log("permission data:", data);
          //   },
          // },
        },
      });

      Tezos.setWalletProvider(wallet_instance);

      const activeAccount = await wallet_instance.client.getActiveAccount();

      if (activeAccount) {
        const userAddress = await wallet_instance.getPKH();
        const publicKey = activeAccount.publicKey;

        dispatch(
          _walletConfig({
            address: userAddress,
            publicKey,
          })
        );
      }

      dispatch(setWallet(wallet_instance));
    })();
  }, [Tezos, dispatch]);

  const handleBoMsg = (event) => {
    try {
      if (config.backofficeUrl.includes(event.origin)) {
        const { target, data } = event.data;
        if (target === "tickets") {
          if (data["user"] && data["pass"]) {
            new Promise((resolve, reject) => {
              dispatch(
                loginUser(
                  {
                    email: data["user"],
                    password: data["pass"],
                  },
                  resolve,
                  reject
                )
              );
            })
              .then(() => {})
              .catch((err) => {
                parent.postMessage(
                  {
                    target: "tickets",
                    data: {
                      error: err.message,
                    },
                  },
                  "*"
                );
              });
          } else if (data["reqLogout"]) {
            dispatch(logoutUser());
          } else {
            throw new Error("No data received!");
          }
        }
      }
    } catch (err) {
      console.log({ err });
    }
  };

  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener("message", handleBoMsg, false);
    } else if (window.attachEvent) {
      // ie8
      window.attachEvent("onmessage", handleBoMsg);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      parent.postMessage(
        {
          target: "tickets",
          data: {
            loginStatus: true,
          },
        },
        "*"
      );
    }
  }, [isAuthenticated]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <SnackbarProvider
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          ref={notistackRef}
          action={(key) => (
            <Button
              variant="text"
              color="inherit"
              onClick={onClickDismiss(key)}
            >
              Dismiss
            </Button>
          )}
        >
          <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {routing}
          </LocalizationProvider>
          <ErrorWarning />
          <ShowSnack />
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
