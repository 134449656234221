import axios from "axios";
import config from "../../config";
import {
  CANCEL_INVITE,
  CREATE_INVITE,
  GET_ERRORS,
  GET_INVITES,
  INVITES_LOADING,
  INVITES_NOT_LOADING,
  SHOW_SNACK,
  GET_INVITE,
  ACCEPT_INVITE,
  INVALID_INVITE,
  DELETE_INVITE,
} from "./types";

export const getInvites = () => (dispatch) => {
  dispatch(setInvitesLoading());

  axios
    .get(`${config.apiUrl}/invites`)
    .then((res) => {
      dispatch({
        type: GET_INVITES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(setInvitesNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const createInvite = (info, resolve, reject) => (dispatch) => {
  return axios
    .post(`${config.apiUrl}/invites`, info)
    .then((res) => {
      dispatch({
        type: CREATE_INVITE,
        payload: res.data,
      });

      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: "success",
          message: res.data.resent
            ? "The invite already exists. The email was resent."
            : "Invite sent.",
        },
      });

      resolve();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });

      reject();
    });
};

export const verifyInvite = (info) => (dispatch) => {
  dispatch(setInvitesLoading());

  return axios
    .get(`${config.apiUrl}/invites/verify`, {
      params: {
        email: info.email,
        uuid: info.uuid,
      },
    })
    .then((data) => {
      dispatch({
        type: GET_INVITE,
        payload: data.data.invite,
      });
    })
    .catch((err) => {
      dispatch({
        type: INVALID_INVITE,
      });

      dispatch({
        type: GET_ERRORS,
        payload: err.response.data ?? "An error has occurred.",
      });
    });
};

export const acceptInvite = (info) => (dispatch) => {
  dispatch(setInvitesLoading());

  axios
    .post(`${config.apiUrl}/invites/accept`, info)
    .then(() => {
      dispatch({
        type: ACCEPT_INVITE,
      });

      // dispatch(
      //   downloadInvite({
      //     email: info.email,
      //     uuid: info.uuid,
      //   })
      // );

      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: "success",
          message:
            "Invite accepted. The PDF with the QR Code will be sent to your email once the invitation is processed.",
        },
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch(setInvitesNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const downloadInvite = (info) => (dispatch) => {
  return axios
    .post(
      `${config.apiUrl}/invites/pdf`,
      {
        email: info.email,
        uuid: info.uuid,
      },
      { responseType: "blob" }
    )
    .then(onFileDownloadSuccess("invite.pdf"))
    .catch(onFileDownloadError(dispatch));
};

export const cancelInvite = (id) => (dispatch) => {
  return axios
    .post(`${config.apiUrl}/invites/${id}/cancel`)
    .then((data) => {
      if (data.data?.status) {
        dispatch({
          type: CANCEL_INVITE,
          payload: {
            id,
          },
        });

        dispatch({
          type: SHOW_SNACK,
          payload: {
            variant: "success",
            message: "Invite canceled.",
          },
        });
      } else {
        dispatch({
          type: SHOW_SNACK,
          payload: {
            variant: "error",
            message: data.data?.error ?? "An error has occurred.",
          },
        });
      }
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const resendInvite = (id) => (dispatch) => {
  dispatch(setInvitesLoading());

  return axios
    .post(`${config.apiUrl}/invites/${id}/resend`)
    .then((data) => {
      dispatch(setInvitesNotLoading());
      if (data.data?.status) {
        dispatch({
          type: SHOW_SNACK,
          payload: {
            variant: "success",
            message: "The invitation email was resent.",
          },
        });
      } else {
        dispatch({
          type: SHOW_SNACK,
          payload: {
            variant: "error",
            message: data.data?.error ?? "An error has occurred.",
          },
        });
      }
    })
    .catch((err) => {
      console.log(err.response);
      dispatch(setInvitesNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const resendInvitePDF = (info) => (dispatch) => {
  dispatch(setInvitesLoading());

  return axios
    .post(`${config.apiUrl}/invites/pdf-email`, {
      email: info.email,
      uuid: info.uuid,
    })
    .then((data) => {
      dispatch(setInvitesNotLoading());
      if (data.data?.status) {
        dispatch({
          type: SHOW_SNACK,
          payload: {
            variant: "success",
            message: "The email with the PDF was resent.",
          },
        });
      } else {
        dispatch({
          type: SHOW_SNACK,
          payload: {
            variant: "error",
            message: data.data?.error ?? "An error has occurred.",
          },
        });
      }
    })
    .catch((err) => {
      console.log(err.response);
      dispatch(setInvitesNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const deleteInvite = (id) => (dispatch) => {
  return axios
    .delete(`${config.apiUrl}/invites/${id}`)
    .then((data) => {
      if (data.data?.status) {
        dispatch({
          type: DELETE_INVITE,
          payload: {
            id,
          },
        });

        dispatch({
          type: SHOW_SNACK,
          payload: {
            variant: "success",
            message: "Invite deleted.",
          },
        });
      } else {
        dispatch({
          type: SHOW_SNACK,
          payload: {
            variant: "error",
            message: data.data?.error ?? "An error has occurred.",
          },
        });
      }
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const setInvitesLoading = () => {
  return {
    type: INVITES_LOADING,
  };
};

export const setInvitesNotLoading = () => {
  return {
    type: INVITES_NOT_LOADING,
  };
};

const onFileDownloadSuccess = (nameFile) => (res) => {
  const filename =
    nameFile ||
    (res.headers["content-disposition"] &&
      res.headers["content-disposition"].split("filename=")[1]) ||
    "download.pdf";
  const url = window.URL.createObjectURL(new Blob([res.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
};

const onFileDownloadError = (dispatch) => (err) => {
  const reader = new FileReader();
  reader.addEventListener("loadend", () => {
    dispatch({
      type: GET_ERRORS,
      payload: JSON.parse(reader.result),
    });
  });

  reader.readAsText(err.response.data);
};
