import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilesList from "../../../components/multimedia/FilesList";
import { getFiles } from "../../../store/actions/multimedia";

const Multimedia = () => {
  const dispatch = useDispatch();
  const [formOpen, setFormOpen] = useState(false);
  const { files, loading } = useSelector((state) => state.multimedia);

  useEffect(() => {
    dispatch(getFiles());
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid item xs={8}>
        <Typography variant="h4" gutterBottom={true}>
          Multimedia
        </Typography>
      </Grid>

      <Grid item xs={4} style={{ textAlign: "right" }}>
        <Button
          onClick={() => {
            setFormOpen((prevState) => !prevState);
          }}
        >
          Add New
        </Button>
      </Grid>

      <Grid item xs={12}>
        <FilesList files={files} loading={loading} showAddForm={formOpen} />
      </Grid>
    </Grid>
  );
};

export default Multimedia;
