import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import Table from "../../common/table/Table";
import { useDispatch } from "react-redux";
import {
  cancelInvite,
  deleteInvite,
  resendInvite,
} from "../../../store/actions/invites";

const TABLE_HEAD = [
  { id: "email", label: "E-mail", alignRight: false },
  { id: "uuid", label: "UUID", alignRight: false },
  { id: "enabled", label: "Status", alignRight: false },
  { id: "accepted", label: "Accepted", alignRight: false },
];

const InvitesList = ({ invites }) => {
  const dispatch = useDispatch();

  if (invites.length === 0) {
    return (
      <Typography variant="h5" gutterBottom={true}>
        No invites found.
      </Typography>
    );
  }

  const handleCancelClick = (id) => {
    var result = confirm("Are you sure you want to cancel the invite?");

    if (result) {
      dispatch(cancelInvite(id));
    }
  };

  const handleDeleteClick = (id) => {
    var result = confirm("Are you sure you want to delete the invite?");

    if (result) {
      dispatch(deleteInvite(id));
    }
  };

  const handleResendClick = (id) => {
    var result = confirm(
      "Are you sure you want to resent the invitation email?"
    );

    if (result) {
      dispatch(resendInvite(id));
    }
  };

  const data = invites.map((invite) => {
    const id = invite._id;
    return {
      ...invite,
      id,
      enabled: invite.enabled ? "Active" : "Canceled",
      accepted: invite.accepted ? "Yes" : "No",
      moreOptions: [
        invite.enabled &&
          !invite.accepted && {
            type: "button",
            onClick: handleResendClick.bind(null, id),
            icon: "eva:sync-outline",
            label: "Resend",
          },
        invite.enabled && {
          type: "button",
          onClick: handleCancelClick.bind(null, id),
          icon: "eva:close-outline",
          label: "Cancel",
        },
        !invite.enabled && {
          type: "button",
          onClick: handleDeleteClick.bind(null, id),
          icon: "eva:close-outline",
          label: "Delete",
        },
      ].filter(Boolean), // remove empty items
    };
  });

  return (
    <Table
      head={TABLE_HEAD}
      data={data}
      withCheckbox
      withMoreMenu
      orderBy="enabled"
    />
  );
};

InvitesList.propTypes = {
  invites: PropTypes.array,
};

export default InvitesList;
