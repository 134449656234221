import { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { Button, Grid, Popover, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import LoadingSpinner from "../common/LoadingSpinner";
import { addFile, deleteFile } from "../../store/actions/multimedia";
import { useDispatch } from "react-redux";

const imgStyle = {
  display: "block",
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "100%",
  width: "auto",
  transform: "translate(-50%,-50%)",
};

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const FilesList = ({
  files,
  loading,
  showAddForm,
  selectedImage,
  setSelectedImage,
}) => {
  const dispatch = useDispatch();

  const addFileHandler = (formData) => {
    new Promise((resolve, reject) => {
      dispatch(addFile(formData, resolve, reject));
    })
      .then(() => {})
      .catch(() => {});
  };

  const deleteFileHandler = (id) => {
    dispatch(deleteFile(id));
  };

  const { getInputProps, getRootProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      // accept: "image/*",
      disabled: loading,
      onDrop: async (acceptedFiles) => {
        for (let i = 0; i < acceptedFiles.length; i++) {
          const file = acceptedFiles[i];

          const formData = new FormData();
          formData.append("file", file);
          formData.append("name", file.name);

          addFileHandler(formData);
        }
      },
    });

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorHash, setAnchorHash] = useState("");

  const thumbs = files.map((file) => {
    let src = "";
    if (file?.url) {
      src = file?.url;
    } else {
      src = file?.preview;
    }

    const open = Boolean(anchorEl);
    const id = open ? file._id : undefined;

    return (
      <Grid item key={file._id} xs={6} md={3} lg={2}>
        <Box
          onClick={() => {
            if (setSelectedImage) {
              setSelectedImage({
                id: file._id,
                url: src,
                name: file.name,
                ipfs: file.ipfs,
              });
            }
          }}
          sx={{
            position: "relative",
            overflow: "hidden",
            height: 200,
            "&:hover": {
              cursor: "pointer",
              opacity: [0.9, 0.8, 0.7],
            },
            border: "solid 2px",
            borderColor:
              selectedImage?.id === file._id ? "primary.main" : "transparent",
          }}
        >
          <img
            src={src}
            style={imgStyle}
            // Revoke data uri after image is loaded
            // onLoad={() => {
            //   // URL.revokeObjectURL(file.preview);
            //   // URL.revokeObjectURL(file.url);
            // }}
          />
        </Box>
        <Typography variant="h5" mt={1} align="center">
          {file.name}{" "}
          {file.ipfs && (
            <Button
              sx={{ p: 0 }}
              aria-describedby={id}
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                setAnchorHash(file.ipfs ?? "");
              }}
            >
              IPFS
            </Button>
          )}
          <Button
            color="error"
            sx={{ p: 0 }}
            onClick={() => {
              deleteFileHandler(file._id);
            }}
          >
            Delete
          </Button>
        </Typography>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          elevation={0}
        >
          <Typography sx={{ p: 2 }}>{anchorHash}</Typography>
        </Popover>
      </Grid>
    );
  });

  // useEffect(() => {
  //   // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
  //   return () => {
  //     // files.forEach((file) => URL.revokeObjectURL(file.preview));
  //     // files.forEach((file) => URL.revokeObjectURL(file.url));
  //   };
  // }, []);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <Grid container rowSpacing={2} sx={{ position: "relative" }}>
      {showAddForm && (
        <Grid item xs={12}>
          <div {...getRootProps({ style })}>
            {!loading && <input {...getInputProps()} />}
            <p>
              {loading
                ? "Loading..."
                : "Drag and drop some files here, or click to select files"}
            </p>
          </div>
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {thumbs}
        </Grid>
      </Grid>
      {loading && <LoadingSpinner />}
    </Grid>
  );
};

FilesList.propTypes = {
  files: PropTypes.array,
  loading: PropTypes.bool,
  showAddForm: PropTypes.bool,
  selectedImage: PropTypes.object,
  setSelectedImage: PropTypes.func,
};
FilesList.defaultProps = {
  files: [],
  showAddForm: true,
  selectedImage: null,
};

export default FilesList;
