import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { ButtonBase } from "@mui/material";

import Logo from "../../../UI/Logo";

const LogoSection = ({ width }) => (
  <ButtonBase disableRipple component={Link} to="/">
    <Logo width={width} />
  </ButtonBase>
);

LogoSection.defaultProps = {
  width: 300,
};

LogoSection.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default LogoSection;
