import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from "@mui/material";

import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import AnimateButton from "../../UI/extended/AnimateButton";

import { resetPassword } from "../../../store/actions/auth";
import LoadingSpinner from "../../common/LoadingSpinner";

const ResetPasswordForm = ({ ...others }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const params = new URLSearchParams(useLocation().search);

  const email = params.get("email");
  const token = params.get("token");

  return (
    <Formik
      initialValues={{
        email: email ?? "",
        password: "",
        password_confirm: "",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required."),
        password: Yup.string()
          .min(6, "Password must be at least 6 characters")
          .required("Password is required."),
        password_confirm: Yup.string()
          .required("Confirm your password.")
          .when("password", {
            is: (password) => password && password.length > 0,
            then: Yup.string().required("Confirm your password."),
          })
          .oneOf([Yup.ref("password")], "The passwords do not match."),
      })}
      onSubmit={(values, { setSubmitting }) => {
        const resetPasswordPromise = new Promise((resolve, reject) => {
          dispatch(
            resetPassword(
              {
                email: values.email,
                token,
                password: values.password,
                password_confirm: values.password_confirm,
              },
              resolve,
              reject
            )
          );
        });

        resetPasswordPromise
          .then(() => {
            navigate("/");
          })
          .catch(() => {})
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit} {...others}>
          <FormControl
            fullWidth
            error={Boolean(touched.email && errors.email)}
            sx={{ ...theme.typography.authInput }}
          >
            <InputLabel htmlFor="outlined-adornment-email">
              Email Address
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-email"
              type="email"
              value={values.email}
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              label="Email Address"
              inputProps={{}}
            />
            {touched.email && errors.email && (
              <FormHelperText error id="standard-weight-helper-text-email">
                {errors.email}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl
            fullWidth
            error={Boolean(touched.password && errors.password)}
            sx={{ ...theme.typography.authInput }}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type="password"
              value={values.password}
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              label="Password"
              inputProps={{}}
            />
            {touched.password && errors.password && (
              <FormHelperText error id="standard-weight-helper-text-password">
                {errors.password}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl
            fullWidth
            error={Boolean(touched.password_confirm && errors.password_confirm)}
            sx={{ ...theme.typography.authInput }}
          >
            <InputLabel htmlFor="outlined-adornment-password_confirm">
              Password confirmation
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password_confirm"
              type="password"
              value={values.password_confirm}
              name="password_confirm"
              onBlur={handleBlur}
              onChange={handleChange}
              label="Password confirmation"
              inputProps={{}}
            />
            {touched.password_confirm && errors.password_confirm && (
              <FormHelperText error id="standard-weight-helper-text-password">
                {errors.password_confirm}
              </FormHelperText>
            )}
          </FormControl>

          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}

          <Box sx={{ mt: 2 }}>
            <AnimateButton>
              <Button
                disableElevation
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="secondary"
              >
                Submit
              </Button>
            </AnimateButton>
          </Box>
          {isSubmitting && <LoadingSpinner />}
        </form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
