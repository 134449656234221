import PropTypes from "prop-types";
import { FieldArray } from "formik";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

import FormikControl from "../../forms/FormikControl";
import TokenAttributeForm from "./TokenAttributeForm";

const TokenForm = ({
  inputs,
  attributes,
  attributesName,
  multimedia,
  onRemove,
  onDuplicate,
  duplicateActive,
  addAttributeActive,
}) => {
  const theme = useTheme();

  const showActions = onDuplicate || onRemove;

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.grey[50],
        borderRadius: "4px",
        border: "solid 1px",
        borderColor: theme.palette.secondary.main,
      }}
    >
      {showActions && (
        <CardActions sx={{ justifyContent: "flex-end", padding: 1 }}>
          <Button onClick={onDuplicate} disabled={!duplicateActive}>
            Duplicate
          </Button>
          {onRemove && (
            <IconButton onClick={onRemove}>
              <DeleteOutline />
            </IconButton>
          )}
        </CardActions>
      )}
      <CardContent sx={{ paddingTop: showActions ? 0 : 3, paddingBottom: 1 }}>
        <Grid container spacing={2}>
          {inputs.map((input, idx) => (
            <Grid key={idx} item xs={6}>
              <FormikControl
                control="input"
                type="text"
                formControlSx={{ ...theme.typography.formInput }}
                {...input}
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <Typography variant="h6" mb={2}>
              Attributes
            </Typography>
            <FieldArray
              name={attributesName}
              render={({ push, remove }) => (
                <Grid container>
                  {attributes.map((attribute, index) => (
                    <Grid key={index} item xs={12}>
                      <TokenAttributeForm
                        key={index}
                        inputs={{
                          type: {
                            name: `${attributesName}.${index}.type`,
                            ...attribute.type,
                          },
                          key: {
                            name: `${attributesName}.${index}.key`,
                            ...attribute.key,
                          },
                          value: {
                            name: `${attributesName}.${index}.value`,
                            ...attribute.value,
                          },
                        }}
                        onRemove={() => {
                          remove(index);
                        }}
                      />
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <Button
                      onClick={() =>
                        push({
                          type: "string",
                          key: "",
                          value: "",
                        })
                      }
                      disabled={!addAttributeActive}
                    >
                      Add attribute
                    </Button>
                  </Grid>
                </Grid>
              )}
            />
          </Grid>
          {multimedia && multimedia.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="h6" mb={2}>
                Files
              </Typography>
              <Grid container spacing={2}>
                {multimedia.map((input, idx) => (
                  <Grid key={idx} item xs={12} sm={6}>
                    <FormikControl control="multimedia-select" {...input} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

TokenForm.propTypes = {
  inputs: PropTypes.array,
  attributes: PropTypes.array,
  attributesName: PropTypes.string,
  multimedia: PropTypes.array,
  onRemove: PropTypes.func,
  onDuplicate: PropTypes.func,
  duplicateActive: PropTypes.bool,
  addAttributeActive: PropTypes.bool,
};

TokenForm.defaultProps = {
  addAttributeActive: true,
  multimedia: [],
};

export default TokenForm;
