import { CLEAR_ERRORS, SHOW_SNACK } from './types';

export const clearSnack = () => dispatch => {
  dispatch({
    type: SHOW_SNACK,
    payload: {},
  });
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};