import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Checkbox,
  TablePagination,
} from "@mui/material";
import TableHead from "./TableHead";
import TableRowMore from "./TableRowMore";

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const applySortFilter = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

const Table = ({
  data,
  head,
  withCheckbox,
  withMoreMenu,
  orderBy: propsOrderBy,
  order: propsOrder,
}) => {
  const [order, setOrder] = useState(propsOrder ?? "asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState(
    head.find((data) => data.id === propsOrderBy) ? propsOrderBy : head[0]?.id
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const filteredData = applySortFilter(data, getComparator(order, orderBy));

  return (
    <Paper>
      <TableContainer>
        <MuiTable sx={{ minWidth: 650 }}>
          <TableHead
            order={order}
            orderBy={orderBy}
            headLabel={head}
            rowCount={data.length}
            withCheckbox={withCheckbox}
            withMoreMenu={withMoreMenu}
            numSelected={selected.length}
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAllClick}
          />
          <TableBody>
            {filteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((data) => {
                const { id } = data;
                const isItemSelected = selected.indexOf(id) !== -1;

                return (
                  <TableRow key={id} hover>
                    {withCheckbox && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onChange={(event) => handleClick(event, id)}
                        />
                      </TableCell>
                    )}
                    {head.map((key) => (
                      <TableCell
                        key={`${id}-${key.id}`}
                        component="th"
                        scope="row"
                      >
                        {data[key.id]}
                      </TableCell>
                    ))}
                    {withMoreMenu && (
                      <TableCell align="right">
                        {data.moreOptions &&
                          Array.isArray(data.moreOptions) &&
                          data.moreOptions.length > 0 && (
                            <TableRowMore menuItems={data.moreOptions} />
                          )}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

Table.propTypes = {
  data: PropTypes.array.isRequired,
  head: PropTypes.array.isRequired,
  withCheckbox: PropTypes.bool,
  withMoreMenu: PropTypes.bool,
  orderBy: PropTypes.string,
  order: PropTypes.oneOf(["asc", "desc"]),
};

export default Table;
