import PropTypes from "prop-types";
import Input from "./Input";
import Select from "./Select";
import CheckboxGroup from "./CheckboxGroup";
import Checkbox from "./Checkbox";
import RadioGroup from "./RadioGroup";
import FileUpload from "./FileUpload";
import DateTimePicker from "./DateTimePicker";
import MultimediaSelect from "./MultimediaSelect";

const FormikControl = ({ control, ...rest }) => {
  switch (control) {
    case "input":
      return <Input {...rest} />;
    case "select":
      return <Select {...rest} />;
    case "checkbox":
      return <Checkbox {...rest} />;
    case "checkbox-group":
      return <CheckboxGroup {...rest} />;
    case "radio-group":
      return <RadioGroup {...rest} />;
    case "file-upload":
      return <FileUpload {...rest} />;
    case "date-time":
      return <DateTimePicker {...rest} />;
    case "multimedia-select":
      return <MultimediaSelect {...rest} />;
  }
};

FormikControl.propTypes = {
  control: PropTypes.string.isRequired,
};

export default FormikControl;
