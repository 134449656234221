import { Button, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LotteriesList from "../../../components/admin/lotteries/LotteriesList";
import LoadingSpinner from "../../../components/common/LoadingSpinner";
import { getLotteries } from "../../../store/actions/lotteries";

const Lotteries = () => {
  const dispatch = useDispatch();
  const { lotteries, loading } = useSelector((state) => state.lotteries);

  useEffect(() => {
    dispatch(getLotteries());
  }, [dispatch]);

  return (
    <Grid container>
      <Grid item xs={8}>
        <Typography variant="h4" gutterBottom={true}>
          Lotteries
        </Typography>
      </Grid>

      <Grid item xs={4} style={{ textAlign: "right" }}>
        <Link to="/admin/new-lottery" style={{ color: "inherit" }}>
          <Button>New Lottery</Button>
        </Link>
      </Grid>

      <Grid item xs={12} mt={1}>
        <LotteriesList
          lotteries={lotteries.map((x) => {
            return { ...x, accepted: !!x.accepted_at };
          })}
        />
      </Grid>
      {loading && <LoadingSpinner />}
    </Grid>
  );
};

export default Lotteries;
