import axios from "axios";
import config from "../../config";
import { GET_CONFIGS, GET_ERRORS, GET_PUBLIC_CONFIGS, SHOW_SNACK, UPDATE_CONFIGS, UPDATE_PUBLIC_CONFIGS } from "./types";

export const getConfigs = () => (dispatch) => {
  axios
    .get(`${config.apiUrl}/configs`)
    .then((res) => {
      dispatch({
        type: GET_CONFIGS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
}

export const getPublicConfigs = () => (dispatch) => {
  axios
    .get(`${config.apiUrl}/configs/public`)
    .then((res) => {
      dispatch({
        type: GET_PUBLIC_CONFIGS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const updateConfigs = (info, resolve, reject) => (dispatch) => {
  axios
    .put(`${config.apiUrl}/configs`, info)
    .then((res) => {
      dispatch({
        type: UPDATE_CONFIGS,
        payload: info.configs
      });

      dispatch({
        type: UPDATE_PUBLIC_CONFIGS,
        payload: info.configs.filter(x => x.key === "chain")
      });
      
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: "success",
          message: "Configurations updated successfully.",
        },
      });

      resolve(res.data);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });

      reject(err);
    });
};
