import React from "react";
import { CircularProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const LoadingSpinner = ({ ...rest }) => {
  const theme = useTheme();

  return <CircularProgress sx={theme.typography.spinner} {...rest} />;
};

export default React.memo(LoadingSpinner);
