import PropTypes from "prop-types";
import { FormControl, FormHelperText, InputLabel, OutlinedInput } from "@mui/material";

const Input = (props) => {
  const { label, name, formControlSx, touched, errors, ...rest } = props;

  return (
    <FormControl fullWidth error={Boolean(touched && errors)} sx={{ ...formControlSx }}>
      <InputLabel htmlFor={`input-${name}`}>{label}</InputLabel>
      <OutlinedInput id={`input-${name}`} name={name} label={label} {...rest} />
      {touched && errors && (
        <FormHelperText error id={`input-helper-${name}`}>
          {errors}
        </FormHelperText>
      )}
    </FormControl>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  formControlSx: PropTypes.object,
  touched: PropTypes.bool,
  errors: PropTypes.any,
};

export default Input;
