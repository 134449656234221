import { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Typography,
} from "@mui/material";
import FilesModal from "../multimedia/FilesModal";
import { useSelector } from "react-redux";

const MultimediaSelect = (props) => {
  const {
    label,
    name,
    formControlSx,
    touched,
    errors,
    required,
    buttonLabel,
    removeLabel,
    previewImage,
    filename,
    imageUrl,
    onChange,
    onRemove,
    modalTitle,
  } = props;

  const [filesModalOpen, setFilesModalOpen] = useState(false);
  const { files } = useSelector((state) => state.multimedia);

  const openModalHandler = () => {
    setFilesModalOpen(true);
  };

  const closeModalHandler = () => {
    setFilesModalOpen(false);
  };

  return (
    <FormControl error={Boolean(touched && errors)} sx={{ ...formControlSx }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormLabel required={required}>{label}</FormLabel>
        </Grid>

        {(filename || imageUrl) && (
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              {filename && (
                <Grid item>
                  <Typography
                    sx={{
                      display: "inline-block",
                      wordBreak: "break-word",
                    }}
                  >
                    {filename}
                  </Typography>
                </Grid>
              )}
              <Grid item>
                <Button variant="text" color="error" onClick={onRemove}>
                  {removeLabel ?? "Remove"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}

        {previewImage && (
          <Grid item xs={12}>
            {imageUrl && (
              <img
                src={imageUrl}
                width="auto"
                style={{ maxWidth: "100%", maxHeight: 120 }}
              />
            )}
          </Grid>
        )}

        <Grid item>
          <label htmlFor={name}>
            <Button
              variant="outlined"
              color="secondary"
              component="span"
              onClick={openModalHandler}
            >
              {buttonLabel ?? "Select"}
            </Button>
          </label>
        </Grid>

        <Grid item>
          <label htmlFor={name}>
            <Button
              variant="text"
              color="secondary"
              component="span"
              onClick={() => {
                const hash = prompt(`${label} IPFS CID:`, "ipfs://");

                if (hash !== null && hash !== "") {
                  onChange({
                    url: null,
                    name: null,
                    ipfs: hash,
                  });
                }
              }}
            >
              {"IPFS CID"}
            </Button>
          </label>
        </Grid>

        <FilesModal
          open={filesModalOpen}
          handleCancel={closeModalHandler}
          handleSelect={(file) => {
            onChange(file);
            closeModalHandler();
          }}
          files={files}
          title={modalTitle}
        />
      </Grid>
      {touched && errors && (
        <FormHelperText error id={`input-helper-${name}`}>
          {errors}
        </FormHelperText>
      )}
    </FormControl>
  );
};

MultimediaSelect.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  formControlSx: PropTypes.object,
  touched: PropTypes.bool,
  errors: PropTypes.any,
  required: PropTypes.bool,
  buttonLabel: PropTypes.string,
  removeLabel: PropTypes.string,
  previewImage: PropTypes.bool,
  filename: PropTypes.string,
  imageUrl: PropTypes.string,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  modalTitle: PropTypes.string,
};

export default MultimediaSelect;
