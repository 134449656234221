import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import Table from "../../common/table/Table";
import { useDispatch } from "react-redux";
import { deleteLottery } from "../../../store/actions/lotteries";

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "date", label: "Date", alignRight: false },
  { id: "number_winners", label: "No. winners", alignRight: false },
  { id: "completed", label: "Completed", alignRight: false },
];

const LotteriesList = ({ lotteries }) => {
  const dispatch = useDispatch();

  if (lotteries.length === 0) {
    return (
      <Typography variant="h5" gutterBottom={true}>
        No lotteries found.
      </Typography>
    );
  }

  const handleDeleteClick = (id) => {
    var result = confirm("Are you sure you want to delete the lottery?");

    if (result) {
      dispatch(deleteLottery(id));
    }
  };

  const data = lotteries.map((lottery) => {
    const id = lottery._id;
    return {
      ...lottery,
      id,
      completed: lottery.completed ? "Yes" : "No",
      moreOptions: [
        !lottery.completed && {
          type: "button",
          onClick: handleDeleteClick.bind(null, id),
          icon: "eva:close-outline",
          label: "Delete",
        },
      ].filter(Boolean), // remove empty items
    };
  });

  return (
    <Table
      head={TABLE_HEAD}
      data={data}
      withCheckbox
      withMoreMenu
      orderBy="enabled"
    />
  );
};

LotteriesList.propTypes = {
  lotteries: PropTypes.array,
};

export default LotteriesList;
