import { Link, useLocation, useNavigate } from "react-router-dom";
import { Typography, Grid, Stack, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import AuthWrapper from "../../components/auth/AuthWrapper";
import AuthCardWrapper from "../../components/auth/AuthCardWrapper";
import AuthLogin from "../../components/auth/auth-forms/AuthLogin";
import AuthFooter from "../../components/UI/cards/AuthFooter";
import Logo from "../../components/UI/Logo";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const Login = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const params = new URLSearchParams(useLocation().search);

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));

  const indicateAvailable = () => {
    const msg = { target: "tickets", data: { availableToLogin: true } };
    parent.postMessage(msg, "*");
  };

  useEffect(() => {
    if (isAuthenticated) {
      const goTo = params.get("go-to");

      if (goTo) {
        navigate(goTo);
      } else {
        navigate("/admin");
      }
    }
  }, [isAuthenticated, history]);

  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener("load", indicateAvailable, false);
    } else if (window.attachEvent) {
      // ie8
      window.attachEvent("onload", indicateAvailable);
    }
  }, []);

  return (
    <AuthWrapper>
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        sx={{ minHeight: "100vh" }}
      >
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: "calc(100vh - 68px)" }}
          >
            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
              <AuthCardWrapper>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item sx={{ mb: 3 }}>
                    <Link to="#">
                      <Logo width={300} />
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction={matchDownSM ? "column-reverse" : "row"}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Stack
                          alignItems="center"
                          justifyContent="center"
                          spacing={1}
                        >
                          <Typography
                            variant="caption"
                            fontSize="16px"
                            textAlign={matchDownSM ? "center" : "inherit"}
                          >
                            Enter your credentials to continue
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <AuthLogin />
                  </Grid>
                </Grid>
              </AuthCardWrapper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
          <AuthFooter />
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

export default Login;
