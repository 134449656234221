import {
  SET_CURRENT_USER,
  AUTH_LOADING,
  AUTH_NOT_LOADING,
  UPDATE_USER,
  SET_LOGOUT_TIMER,
} from "../actions/types";
import isEmpty from "../../utils/isEmpty";

const initialState = {
  user: {
    email: "",
    name: "",
  },
  isAuthenticated: false,
  loading: false,
  logoutTimer: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: { ...state.user, ...action.payload },
        loading: false,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    case AUTH_LOADING:
      return {
        ...state,
        loading: true,
      };
    case AUTH_NOT_LOADING:
      return {
        ...state,
        loading: false,
      };
    case SET_LOGOUT_TIMER:
      return {
        ...state,
        logoutTimer: action.payload,
      };
    default:
      return state;
  }
};
