import PropTypes from "prop-types";
import { DateTimePicker as MuiDateTimePicker } from "@mui/lab";
import {
  FormControl,
  FormHelperText,
  // OutlinedInput,
  TextField,
} from "@mui/material";

const DateTimePicker = (props) => {
  const {
    label,
    name,
    formControlSx,
    touched,
    errors,
    onBlur,
    onChange,
    ...rest
  } = props;

  return (
    <FormControl
      fullWidth
      error={Boolean(touched && errors)}
      sx={{ ...formControlSx }}
    >
      <MuiDateTimePicker
        id={`input-${name}`}
        name={name}
        label={label}
        inputFormat="dd/MM/yyyy hh:mm"
        ampm={false}
        ampmInClock={false}
        onChange={onChange}
        {...rest}
        renderInput={(params) => (
          <TextField {...params} onBlur={onBlur} autoComplete="off" />
        )}
      />
      {touched && errors && (
        <FormHelperText error id={`input-helper-${name}`}>
          {errors}
        </FormHelperText>
      )}
    </FormControl>
  );
};

DateTimePicker.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  formControlSx: PropTypes.object,
  touched: PropTypes.any,
  errors: PropTypes.any,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

export default DateTimePicker;
