import { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import FilesList from "./FilesList";

const FilesModal = ({ open, handleCancel, handleSelect, files, title }) => {
  const [formOpen, setFormOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleCancel();
        setFormOpen(false);
      }}
      maxWidth="lg"
      fullWidth={true}
      aria-labelledby="multimedia-dialog-title"
    >
      <DialogTitle
        id="multimedia-dialog-title"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {title}
        <Button
          onClick={() => {
            setFormOpen((prevState) => !prevState);
          }}
        >
          Add New
        </Button>
      </DialogTitle>
      <DialogContent>
        <FilesList
          files={files}
          showAddForm={formOpen}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
        />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => {
            handleCancel();
            setFormOpen(false);
            setSelectedImage(null);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (selectedImage) {
              handleSelect(selectedImage);
              setFormOpen(false);
              setSelectedImage(null);
            }
          }}
          autoFocus
          disabled={!selectedImage}
        >
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FilesModal.propTypes = {
  open: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleSelect: PropTypes.func,
  files: PropTypes.array,
  title: PropTypes.string,
};

FilesModal.defaultProps = {
  title: "Multimedia",
  file: [],
};

export default FilesModal;
