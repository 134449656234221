import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { getConfigs } from "../../../store/actions/configs";
import { getFiles } from "../../../store/actions/multimedia";
import MainCard from "../../../components/UI/cards/MainCard";
import FormikControl from "../../../components/forms/FormikControl";
import { createLottery } from "../../../store/actions/lotteries";
import TokenForm from "../../../components/admin/lotteries/TokenForm";

const NewLottery = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { configs } = useSelector((state) => state.configs);
  const { chain } = configs;

  useEffect(() => {
    dispatch(getConfigs());
    dispatch(getFiles());
  }, []);

  const attributeInitialValue = {
    type: "string",
    key: "",
    value: "",
  };

  const tokenInitialValue = {
    name: "",
    symbol: "",
    description: "",
    attributes: [attributeInitialValue],
    artifact: { id: "", name: "", url: "" },
    display: { id: "", name: "", url: "" },
    thumbnail: { id: "", name: "", url: "" },
    image: { id: "", name: "", url: "" },
    animation: { id: "", name: "", url: "" },
  };

  return (
    <MainCard title="New Lottery">
      <Formik
        initialValues={{
          name: "",
          date: null,
          equal_tokens: false,
          number_tokens: "",
          tokens: [tokenInitialValue],
          token: tokenInitialValue,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Name is required."),
          date: Yup.date()
            .typeError("Invalid Date")
            .nullable()
            .transform((curr, orig) => (orig === "" ? null : curr))
            .required("Date is required."),
          equal_tokens: Yup.bool(),
          number_tokens: Yup.number()
            .integer()
            .when("equal_tokens", {
              is: (equal_tokens) => equal_tokens,
              then: Yup.number()
                .integer()
                .required("Number of tokens is required.")
                .min(1, "Minimum of 1 token."),
            }),
          tokens: Yup.array()
            .of(
              Yup.object().shape({
                name: Yup.string(),
                symbol: Yup.string(),
                description: Yup.string(),
              })
            )
            .when("equal_tokens", {
              is: (equal_tokens) => !equal_tokens,
              then: Yup.array()
                .of(
                  Yup.object().shape({
                    name: Yup.string().required("Token name is required."),
                    symbol:
                      chain === "tezos"
                        ? Yup.string().required("Token symbol is required.")
                        : Yup.string(),
                    description: Yup.string().required(
                      "Token description is required."
                    ),
                  })
                )
                .min(1, "Minimum of 1 token.")
                .required("Must have tokens"),
            }),
          token: Yup.object()
            .shape({
              name: Yup.string(),
              symbol: Yup.string(),
              description: Yup.string(),
            })
            .when("equal_tokens", {
              is: (equal_tokens) => equal_tokens,
              then: Yup.object()
                .shape({
                  name: Yup.string().required("Token name is required."),
                  symbol:
                    chain === "tezos"
                      ? Yup.string().required("Token symbol is required.")
                      : Yup.string(),
                  description: Yup.string().required(
                    "Token description is required."
                  ),
                })
                .required("Must have tokens"),
            }),
        })}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          const d = new Date(values.date);

          const month = d.toLocaleString("en-US", { month: "2-digit" });
          const day = d.toLocaleString("en-US", { day: "2-digit" });
          const year = d.getFullYear();

          const hours = ("0" + d.getHours()).slice(-2);
          const minutes = ("0" + d.getMinutes()).slice(-2);

          const formattedDate = `${year}/${month}/${day} ${hours}:${minutes}`;

          const { artifact, display, thumbnail, image, animation, symbol, ...rest } =
            values.token;

          const token = {
            ...rest,
            symbol: chain === "tezos" ? symbol || null : null,
            artifact_hash: chain === "tezos" ? artifact.hash || null : null,
            display_hash: chain === "tezos" ? display.hash || null : null,
            thumbnail_hash: chain === "tezos" ? thumbnail.hash || null : null,
            image_hash: chain === "ethereum" ? image.hash || null : null,
            animation_hash: chain === "ethereum" ? animation.hash || null : null,
          };

          const tokens = values.tokens.map((token) => {
            const { artifact, display, thumbnail, image, animation, symbol, ...rest } =
              token;

            return {
              ...rest,
              symbol: chain === "tezos" ? symbol || null : null,
              artifact_hash: chain === "tezos" ? artifact.hash || null : null,
              display_hash: chain === "tezos" ? display.hash || null : null,
              thumbnail_hash: chain === "tezos" ? thumbnail.hash || null : null,
              image_hash: chain === "ethereum" ? image.hash || null : null,
              animation_hash: chain === "ethereum" ? animation.hash || null : null,
            };
          });

          new Promise((resolve, reject) => {
            dispatch(
              createLottery(
                {
                  name: values.name,
                  date: formattedDate,
                  equal_tokens: values.equal_tokens,
                  number_tokens: values.equal_tokens
                    ? values.number_tokens
                    : values.tokens.length,
                  token: values.equal_tokens ? token : null,
                  tokens: !values.equal_tokens ? tokens : null,
                },
                resolve,
                reject
              )
            );
          })
            .then(() => {
              resetForm();
            })
            .catch(() => {})
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
          setFieldTouched,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container columnSpacing={2}>
              <Grid item xs={12}>
                <FormikControl
                  control="input"
                  type="text"
                  label="Name"
                  name="name"
                  formControlSx={{ ...theme.typography.formInput }}
                  value={values.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  touched={touched.name}
                  errors={errors.name}
                />
              </Grid>

              <Grid item xs={6}>
                <FormikControl
                  control="date-time"
                  type="date"
                  label="Date"
                  name="date"
                  formControlSx={{ ...theme.typography.formInput }}
                  value={values.date}
                  onBlur={() => {
                    setFieldTouched("false", true);
                  }}
                  onChange={(value) => setFieldValue("date", value)}
                  touched={touched.date}
                  errors={errors.date}
                />
              </Grid>

              <Grid item xs={12} sx={{ my: 2 }}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  Tokens
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormikControl
                  control="checkbox"
                  label="The tokens have the same metadata"
                  name="equal_tokens"
                  value={values.equal_tokens}
                  formControlSx={{ ...theme.typography.formInput }}
                  onBlur={handleBlur}
                  onChange={() => {
                    setFieldValue("equal_tokens", !values.equal_tokens);
                  }}
                  errors={errors.equal_tokens}
                />
              </Grid>

              {values.equal_tokens && (
                <Grid item xs={12} md={6}>
                  <FormikControl
                    control="input"
                    type="number"
                    label="Number of tokens"
                    name="number_tokens"
                    formControlSx={{ ...theme.typography.formInput }}
                    value={values.number_tokens}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    touched={touched.number_tokens}
                    errors={errors.number_tokens}
                  />
                </Grid>
              )}

              {!values.equal_tokens && (
                <Grid item xs={12}>
                  <FieldArray
                    name="tokens"
                    render={({ push, remove, insert }) => (
                      <Grid container spacing={2}>
                        {values.tokens.map((token, index) => (
                          <Grid key={index} item xs={12} xl={6}>
                            <TokenForm
                              inputs={[
                                {
                                  name: `tokens.${index}.name`,
                                  value: token.name,
                                  onBlur: handleBlur,
                                  onChange: handleChange,
                                  touched: touched.tokens?.[index]?.name,
                                  errors: errors.tokens?.[index]?.name,
                                  label: "Token name",
                                },
                                chain === "tezos" && {
                                  name: `tokens.${index}.symbol`,
                                  value: token.symbol,
                                  onBlur: handleBlur,
                                  onChange: handleChange,
                                  touched: touched.tokens?.[index]?.symbol,
                                  errors: errors.tokens?.[index]?.symbol,
                                  label: "Token symbol",
                                },
                                {
                                  name: `tokens.${index}.description`,
                                  value: token.description,
                                  onBlur: handleBlur,
                                  onChange: handleChange,
                                  touched: touched.tokens?.[index]?.description,
                                  errors: errors.tokens?.[index]?.description,
                                  label: "Token description",
                                },
                              ].filter(Boolean)}
                              attributesName={`tokens.${index}.attributes`}
                              attributes={token.attributes.map(
                                (attribute, idx) => {
                                  return {
                                    type: {
                                      value: attribute.type,
                                      onBlur: handleBlur,
                                      onChange: handleChange,
                                      touched:
                                        touched.tokens?.[index]?.attributes?.[
                                          idx
                                        ]?.type,
                                      errors:
                                        errors.tokens?.[index]?.attributes?.[
                                          idx
                                        ]?.type,
                                    },
                                    key: {
                                      value: attribute.key,
                                      onBlur: handleBlur,
                                      onChange: handleChange,
                                      touched:
                                        touched.tokens?.[index]?.attributes?.[
                                          idx
                                        ]?.key,
                                      errors:
                                        errors.tokens?.[index]?.attributes?.[
                                          idx
                                        ]?.key,
                                    },
                                    value: {
                                      value: attribute.value,
                                      onBlur: handleBlur,
                                      onChange: handleChange,
                                      touched:
                                        touched.tokens?.[index]?.attributes?.[
                                          idx
                                        ]?.value,
                                      errors:
                                        errors.tokens?.[index]?.attributes?.[
                                          idx
                                        ]?.value,
                                    },
                                  };
                                }
                              )}
                              multimedia={[
                                chain === "tezos" && {
                                  name: `tokens.${index}.artifact.hash`,
                                  value: token.artifact.hash,
                                  onBlur: handleBlur,
                                  onChange: (file) => {
                                    setFieldValue(`tokens.${index}.artifact`, {
                                      hash: file.ipfs,
                                      name: file.name,
                                      url: file.url,
                                    });
                                  },
                                  onRemove: () => {
                                    setFieldValue(`tokens.${index}.artifact`, {
                                      hash: "",
                                      name: "",
                                      url: "",
                                    });
                                  },
                                  touched:
                                    touched.tokens?.[index]?.artifact?.hash,
                                  errors:
                                    errors.tokens?.[index]?.artifact?.hash,
                                  label: "Artifact",
                                  previewImage: true,
                                  imageUrl: token.artifact.url,
                                  filename: token.artifact.name,
                                  modalTitle: "Select the artifact image",
                                },
                                chain === "tezos" && {
                                  name: `tokens.${index}.display.hash`,
                                  value: token.display.hash,
                                  onBlur: handleBlur,
                                  onChange: (file) => {
                                    setFieldValue(`tokens.${index}.display`, {
                                      hash: file.ipfs,
                                      name: file.name,
                                      url: file.url,
                                    });
                                  },
                                  onRemove: () => {
                                    setFieldValue(`tokens.${index}.display`, {
                                      hash: "",
                                      name: "",
                                      url: "",
                                    });
                                  },
                                  touched:
                                    touched.tokens?.[index]?.display?.hash,
                                  errors: errors.tokens?.[index]?.display?.hash,
                                  label: "Display",
                                  previewImage: true,
                                  imageUrl: token.display.url,
                                  filename: token.display.name,
                                  modalTitle: "Select the display image",
                                },
                                chain === "tezos" && {
                                  name: `tokens.${index}.thumbnail.hash`,
                                  value: token.thumbnail.hash,
                                  onBlur: handleBlur,
                                  onChange: (file) => {
                                    setFieldValue(`tokens.${index}.thumbnail`, {
                                      hash: file.ipfs,
                                      name: file.name,
                                      url: file.url,
                                    });
                                  },
                                  onRemove: () => {
                                    setFieldValue(`tokens.${index}.thumbnail`, {
                                      hash: "",
                                      name: "",
                                      url: "",
                                    });
                                  },
                                  touched:
                                    touched.tokens?.[index]?.thumbnail?.hash,
                                  errors:
                                    errors.tokens?.[index]?.thumbnail?.hash,
                                  label: "Thumbnail",
                                  previewImage: true,
                                  imageUrl: token.thumbnail.url,
                                  filename: token.thumbnail.name,
                                  modalTitle: "Select the thumbnail image",
                                },
                                chain === "ethereum" && {
                                  name: `tokens.${index}.image.hash`,
                                  value: token.image.hash,
                                  onBlur: handleBlur,
                                  onChange: (file) => {
                                    setFieldValue(`tokens.${index}.image`, {
                                      hash: file.ipfs,
                                      name: file.name,
                                      url: file.url,
                                    });
                                  },
                                  onRemove: () => {
                                    setFieldValue(`tokens.${index}.image`, {
                                      hash: "",
                                      name: "",
                                      url: "",
                                    });
                                  },
                                  touched: touched.tokens?.[index]?.image?.hash,
                                  errors: errors.tokens?.[index]?.image?.hash,
                                  label: "Image",
                                  previewImage: true,
                                  imageUrl: token.image.url,
                                  filename: token.image.name,
                                  modalTitle: "Select the image",
                                },
                                chain === "ethereum" && {
                                  name: `tokens.${index}.animation.hash`,
                                  value: token.animation.hash,
                                  onBlur: handleBlur,
                                  onChange: (file) => {
                                    setFieldValue(`tokens.${index}.animation`, {
                                      hash: file.ipfs,
                                      name: file.name,
                                      url: file.url,
                                    });
                                  },
                                  onRemove: () => {
                                    setFieldValue(`tokens.${index}.animation`, {
                                      hash: "",
                                      name: "",
                                      url: "",
                                    });
                                  },
                                  touched: touched.tokens?.[index]?.animation?.hash,
                                  errors: errors.tokens?.[index]?.animation?.hash,
                                  label: "Animation",
                                  previewImage: true,
                                  imageUrl: token.animation.url,
                                  filename: token.animation.name,
                                  modalTitle: "Select the animation",
                                },
                              ].filter(Boolean)}
                              onRemove={() => {
                                remove(index);
                              }}
                              onDuplicate={() => {
                                insert(index + 1, token);
                              }}
                              duplicateActive
                            />
                          </Grid>
                        ))}
                        <Grid item xs={12}>
                          <Button
                            onClick={() => push(tokenInitialValue)}
                            // disabled={
                            //   values.number_winners <= values.tokens.length
                            // }
                          >
                            Add Token
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          {touched.tokens &&
                            errors.tokens &&
                            typeof errors.tokens === "string" && (
                              <FormHelperText error id={`input-helper-tokens`}>
                                {errors.tokens}
                              </FormHelperText>
                            )}
                        </Grid>
                      </Grid>
                    )}
                  />
                </Grid>
              )}

              {values.equal_tokens && (
                <Grid item xs={12}>
                  <TokenForm
                    inputs={[
                      {
                        name: "token.name",
                        value: values.token?.name,
                        onBlur: handleBlur,
                        onChange: handleChange,
                        touched: touched.token?.name,
                        errors: errors.token?.name,
                        label: "Token name",
                      },
                      chain === "tezos" && {
                        name: "token.symbol",
                        value: values.token?.symbol,
                        onBlur: handleBlur,
                        onChange: handleChange,
                        touched: touched.token?.symbol,
                        errors: errors.token?.symbol,
                        label: "Token symbol",
                      },
                      {
                        name: "token.description",
                        value: values.token?.description,
                        onBlur: handleBlur,
                        onChange: handleChange,
                        touched: touched.token?.description,
                        errors: errors.token?.description,
                        label: "Token description",
                      },
                    ].filter(Boolean)}
                    attributesName={`token.attributes`}
                    attributes={values.token.attributes.map(
                      (attribute, idx) => {
                        return {
                          type: {
                            value: attribute.type,
                            onBlur: handleBlur,
                            onChange: handleChange,
                            touched: touched.token?.attributes?.[idx]?.type,
                            errors: errors.token?.attributes?.[idx]?.type,
                          },
                          key: {
                            value: attribute.key,
                            onBlur: handleBlur,
                            onChange: handleChange,
                            touched: touched.token?.attributes?.[idx]?.key,
                            errors: errors.token?.attributes?.[idx]?.key,
                          },
                          value: {
                            value: attribute.value,
                            onBlur: handleBlur,
                            onChange: handleChange,
                            touched: touched.token?.attributes?.[idx]?.value,
                            errors: errors.token?.attributes?.[idx]?.value,
                          },
                        };
                      }
                    )}
                    multimedia={[
                      chain === "tezos" && {
                        name: `token.artifact.hash`,
                        value: values.token?.artifact.hash,
                        onBlur: handleBlur,
                        onChange: (file) => {
                          setFieldValue(`token.artifact`, {
                            hash: file.ipfs,
                            name: file.name,
                            url: file.url,
                          });
                        },
                        onRemove: () => {
                          setFieldValue(`token.artifact`, {
                            hash: "",
                            name: "",
                            url: "",
                          });
                        },
                        touched: touched.token?.artifact?.hash,
                        errors: errors.token?.artifact?.hash,
                        label: "Artifact",
                        previewImage: true,
                        imageUrl: values.token?.artifact.url,
                        filename: values.token?.artifact.name,
                        modalTitle: "Select the artifact image",
                      },
                      chain === "tezos" && {
                        name: `token.display.hash`,
                        value: values.token?.display.hash,
                        onBlur: handleBlur,
                        onChange: (file) => {
                          setFieldValue(`token.display`, {
                            hash: file.hash,
                            name: file.name,
                            url: file.url,
                          });
                        },
                        onRemove: () => {
                          setFieldValue(`token.display`, {
                            hash: "",
                            name: "",
                            url: "",
                          });
                        },
                        touched: touched.token?.display?.hash,
                        errors: errors.token?.display?.hash,
                        label: "Display",
                        previewImage: true,
                        imageUrl: values.token?.display.url,
                        filename: values.token?.display.name,
                        modalTitle: "Select the display image",
                      },
                      chain === "tezos" && {
                        name: `token.thumbnail.hash`,
                        value: values.token?.thumbnail.hash,
                        onBlur: handleBlur,
                        onChange: (file) => {
                          setFieldValue(`token.thumbnail`, {
                            hash: file.hash,
                            name: file.name,
                            url: file.url,
                          });
                        },
                        onRemove: () => {
                          setFieldValue(`token.thumbnail`, {
                            hash: "",
                            name: "",
                            url: "",
                          });
                        },
                        touched: touched.token?.thumbnail?.hash,
                        errors: errors.token?.thumbnail?.hash,
                        label: "Thumbnail",
                        previewImage: true,
                        imageUrl: values.token?.thumbnail.url,
                        filename: values.token?.thumbnail.name,
                        modalTitle: "Select the thumbnail image",
                      },
                      chain === "ethereum" && {
                        name: `token.image.hash`,
                        value: values.token?.image.hash,
                        onBlur: handleBlur,
                        onChange: (file) => {
                          setFieldValue(`token.image`, {
                            hash: file.ipfs,
                            name: file.name,
                            url: file.url,
                          });
                        },
                        onRemove: () => {
                          setFieldValue(`token.image`, {
                            hash: "",
                            name: "",
                            url: "",
                          });
                        },
                        touched: touched.token?.image?.hash,
                        errors: errors.token?.image?.hash,
                        label: "Image",
                        previewImage: true,
                        imageUrl: values.token?.image.url,
                        filename: values.token?.image.name,
                        modalTitle: "Select the image",
                      },
                      chain === "ethereum" && {
                        name: `token.animation.hash`,
                        value: values.token?.animation.hash,
                        onBlur: handleBlur,
                        onChange: (file) => {
                          setFieldValue(`token.animation`, {
                            hash: file.ipfs,
                            name: file.name,
                            url: file.url,
                          });
                        },
                        onRemove: () => {
                          setFieldValue(`token.animation`, {
                            hash: "",
                            name: "",
                            url: "",
                          });
                        },
                        touched: touched.token?.animation?.hash,
                        errors: errors.token?.animation?.hash,
                        label: "Animation",
                        previewImage: true,
                        imageUrl: values.token?.animation.url,
                        filename: values.token?.animation.name,
                        modalTitle: "Select the animation",
                      },
                    ].filter(Boolean)}
                  />
                </Grid>
              )}
            </Grid>

            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <Box sx={{ mt: 2 }}>
              <Button
                disableElevation
                disabled={isSubmitting}
                size="large"
                type="submit"
                variant="contained"
                color="secondary"
              >
                Create Lottery
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </MainCard>
  );
};

export default NewLottery;
