import { Formik } from "formik";
import * as Yup from "yup";
import { Box, Button, FormHelperText, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { isEqual } from "lodash";

import MainCard from "../../../components/UI/cards/MainCard";
import FormikControl from "../../../components/forms/FormikControl";
import { useDispatch, useSelector } from "react-redux";
import { createInvite } from "../../../store/actions/invites";
import TokenForm from "../../../components/admin/lotteries/TokenForm";
import { useEffect, useState } from "react";
import { getConfigs } from "../../../store/actions/configs";
import { getFiles } from "../../../store/actions/multimedia";

const NewInvite = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [customData, setCustomData] = useState(false);

  const { configs, loading: loadingConfigs } = useSelector(
    (state) => state.configs
  );
  const { files, loading: loadingFiles } = useSelector(
    (state) => state.multimedia
  );

  const loading = loadingConfigs || loadingFiles;

  const {
    chain,
    default_invite_name,
    default_invite_symbol,
    default_invite_description,
    default_invite_attributes,
  } = configs;

  useEffect(() => {
    dispatch(getConfigs());
    dispatch(getFiles());
  }, []);

  useEffect(() => {
    const artifact = files.find(
      (x) => x.ipfs === configs?.default_invite_artifact_hash
    );

    if (artifact) {
      setDefaultInviteArtifact({
        url: artifact.url,
        name: artifact.name,
      });
    }

    const display = files.find(
      (x) => x.ipfs === configs?.default_invite_display_hash
    );

    if (display) {
      setDefaultInviteDisplay({
        url: display.url,
        name: display.name,
      });
    }

    const thumbnail = files.find(
      (x) => x.ipfs === configs?.default_invite_thumbnail_hash
    );

    if (thumbnail) {
      setDefaultInviteThumbnail({
        url: thumbnail.url,
        name: thumbnail.name,
      });
    }

    const image = files.find(
      (x) => x.ipfs === configs?.default_invite_image_hash
    );

    if (image) {
      setDefaultInviteImage({
        url: image.url,
        name: image.name,
      });
    }

    const animation = files.find(
      (x) => x.ipfs === configs?.default_invite_animation_hash
    );

    if (animation) {
      setDefaultInviteAnimation({
        url: animation.url,
        name: animation.name,
      });
    }
  }, [files, configs]);

  const [defaultInviteArtifact, setDefaultInviteArtifact] = useState({
    name: "",
    url: "",
  });

  const [defaultInviteDisplay, setDefaultInviteDisplay] = useState({
    name: "",
    url: "",
  });

  const [defaultInviteThumbnail, setDefaultInviteThumbnail] = useState({
    name: "",
    url: "",
  });

  const [defaultInviteImage, setDefaultInviteImage] = useState({
    name: "",
    url: "",
  });

  const [defaultInviteAnimation, setDefaultInviteAnimation] = useState({
    name: "",
    url: "",
  });

  const attributeInitialValue = {
    type: "string",
    key: "",
    value: "",
  };

  const tokenInitialValue = {
    name: default_invite_name,
    symbol: default_invite_symbol,
    description: default_invite_description,
    attributes:
      default_invite_attributes?.length > 0
        ? default_invite_attributes
        : [attributeInitialValue],
    artifact: {
      hash: configs?.default_invite_artifact_hash ?? "",
      name: defaultInviteArtifact.name,
      url: defaultInviteArtifact.url,
    },
    display: {
      hash: configs?.default_invite_display_hash ?? "",
      name: defaultInviteDisplay.name,
      url: defaultInviteDisplay.url,
    },
    thumbnail: {
      hash: configs?.default_invite_thumbnail_hash ?? "",
      name: defaultInviteThumbnail.name,
      url: defaultInviteThumbnail.url,
    },
    image: {
      hash: configs?.default_invite_image_hash ?? "",
      name: defaultInviteImage.name,
      url: defaultInviteImage.url,
    },
    animation: {
      hash: configs?.default_invite_animation_hash ?? "",
      name: defaultInviteAnimation.name,
      url: defaultInviteAnimation.url,
    },
  };

  const initialValues = {
    email: "",
    data: tokenInitialValue,
  };

  return (
    <MainCard title="New Invite" loading={loading}>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Please insert a valid e-mail")
            .required("E-mail is required."),

          data: Yup.object()
            .shape({
              name: Yup.string(),
              symbol: Yup.string(),
              description: Yup.string(),
            })
            .when("custom_data", {
              is: (custom_data) => custom_data,
              then: Yup.object().shape({
                name: Yup.string().required("Token name is required."),
                symbol:
                  chain === "tezos"
                    ? Yup.string().required("Token symbol is required.")
                    : Yup.string(),
                description: Yup.string().required(
                  "Token description is required."
                ),
              }),
            }),
        })}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          const { artifact, display, thumbnail, image, animation, symbol, ...rest } =
            values.data;

          const data = {
            ...rest,
            symbol: chain === "tezos" ? symbol || null : null,
            artifact_hash: chain === "tezos" ? artifact.hash || null : null,
            display_hash: chain === "tezos" ? display.hash || null : null,
            thumbnail_hash: chain === "tezos" ? thumbnail.hash || null : null,
            image_hash: chain === "ethereum" ? image.hash || null : null,
            animation_hash: chain === "ethereum" ? animation.hash || null : null,
          };

          new Promise((resolve, reject) => {
            dispatch(
              createInvite(
                {
                  email: values.email,
                  custom_data: customData,
                  data: customData ? data : null,
                },
                resolve,
                reject
              )
            );
          })
            .then(() => {
              resetForm();
              setCustomData(false);
            })
            .catch(() => {})
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
          resetForm,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12}>
                <FormikControl
                  control="input"
                  type="email"
                  label="E-mail"
                  name="email"
                  formControlSx={{ ...theme.typography.formInput }}
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  touched={touched.email}
                  errors={errors.email}
                  disabled={loading}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormikControl
                  control="checkbox"
                  label="The invite has custom data"
                  name="custom_data"
                  value={customData}
                  checked={customData}
                  formControlSx={{ ...theme.typography.formInput }}
                  onBlur={handleBlur}
                  onChange={() => {
                    const changed = !isEqual(initialValues.data, values.data);

                    if (changed && customData) {
                      const result = confirm(
                        "Are you sure you want to discard your changes?"
                      );

                      if (result) {
                        setCustomData((prevState) => !prevState);

                        resetForm();

                        setFieldValue("email", values.email);
                      }
                    } else setCustomData((prevState) => !prevState);
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TokenForm
                  inputs={[
                    {
                      name: "data.name",
                      value: values.data?.name,
                      onBlur: handleBlur,
                      onChange: handleChange,
                      touched: touched.data?.name,
                      errors: errors.data?.name,
                      disabled: !customData,
                      label: "Token name",
                    },
                    chain === "tezos" && {
                      name: "data.symbol",
                      value: values.data?.symbol,
                      onBlur: handleBlur,
                      onChange: handleChange,
                      touched: touched.data?.symbol,
                      errors: errors.data?.symbol,
                      disabled: !customData,
                      label: "Token symbol",
                    },
                    {
                      name: "data.description",
                      value: values.data?.description,
                      onBlur: handleBlur,
                      onChange: handleChange,
                      touched: touched.data?.description,
                      errors: errors.data?.description,
                      disabled: !customData,
                      label: "Token description",
                    },
                  ].filter(Boolean)}
                  attributesName={`data.attributes`}
                  attributes={values.data.attributes.map((attribute, idx) => {
                    return {
                      type: {
                        value: attribute.type,
                        onBlur: handleBlur,
                        onChange: handleChange,
                        touched: touched.data?.attributes?.[idx]?.type,
                        errors: errors.data?.attributes?.[idx]?.type,
                        disabled: !customData,
                      },
                      key: {
                        value: attribute.key,
                        onBlur: handleBlur,
                        onChange: handleChange,
                        touched: touched.data?.attributes?.[idx]?.key,
                        errors: errors.data?.attributes?.[idx]?.key,
                        disabled: !customData,
                      },
                      value: {
                        value: attribute.value,
                        onBlur: handleBlur,
                        onChange: handleChange,
                        touched: touched.data?.attributes?.[idx]?.value,
                        errors: errors.data?.attributes?.[idx]?.value,
                        disabled: !customData,
                      },
                    };
                  })}
                  addAttributeActive={customData}
                  multimedia={[
                    chain === "tezos" && {
                      name: `data.artifact.hash`,
                      value: values.data?.artifact.hash,
                      onBlur: handleBlur,
                      onChange: (file) => {
                        setFieldValue(`data.artifact`, {
                          hash: file.ipfs,
                          name: file.name,
                          url: file.url,
                        });
                      },
                      onRemove: () => {
                        setFieldValue(`data.artifact`, {
                          hash: "",
                          name: "",
                          url: "",
                        });
                      },
                      touched: touched.data?.artifact?.hash,
                      errors: errors.data?.artifact?.hash,
                      label: "Artifact",
                      previewImage: true,
                      imageUrl: values.data?.artifact.url,
                      filename: values.data?.artifact.name,
                      modalTitle: "Select the artifact image",
                    },
                    chain === "tezos" && {
                      name: `data.display.hash`,
                      value: values.data?.display.hash,
                      onBlur: handleBlur,
                      onChange: (file) => {
                        setFieldValue(`data.display`, {
                          hash: file.ipfs,
                          name: file.name,
                          url: file.url,
                        });
                      },
                      onRemove: () => {
                        setFieldValue(`data.display`, {
                          hash: "",
                          name: "",
                          url: "",
                        });
                      },
                      touched: touched.data?.display?.hash,
                      errors: errors.data?.display?.hash,
                      label: "Display",
                      previewImage: true,
                      imageUrl: values.data?.display.url,
                      filename: values.data?.display.name,
                      modalTitle: "Select the display image",
                    },
                    chain === "tezos" && {
                      name: `data.thumbnail.hash`,
                      value: values.data?.thumbnail.hash,
                      onBlur: handleBlur,
                      onChange: (file) => {
                        setFieldValue(`data.thumbnail`, {
                          hash: file.ipfs,
                          name: file.name,
                          url: file.url,
                        });
                      },
                      onRemove: () => {
                        setFieldValue(`data.thumbnail`, {
                          hash: "",
                          name: "",
                          url: "",
                        });
                      },
                      touched: touched.data?.thumbnail?.hash,
                      errors: errors.data?.thumbnail?.hash,
                      label: "Thumbnail",
                      previewImage: true,
                      imageUrl: values.data?.thumbnail.url,
                      filename: values.data?.thumbnail.name,
                      modalTitle: "Select the thumbnail image",
                    },
                    chain === "ethereum" && {
                      name: `data.image.hash`,
                      value: values.data?.image.hash,
                      onBlur: handleBlur,
                      onChange: (file) => {
                        setFieldValue(`data.image`, {
                          hash: file.ipfs,
                          name: file.name,
                          url: file.url,
                        });
                      },
                      onRemove: () => {
                        setFieldValue(`data.image`, {
                          hash: "",
                          name: "",
                          url: "",
                        });
                      },
                      touched: touched.data?.image?.hash,
                      errors: errors.data?.image?.hash,
                      label: "Image",
                      previewImage: true,
                      imageUrl: values.data?.image.url,
                      filename: values.data?.image.name,
                      modalTitle: "Select the image",
                    },
                    chain === "ethereum" && {
                      name: `data.animation.hash`,
                      value: values.data?.animation.hash,
                      onBlur: handleBlur,
                      onChange: (file) => {
                        setFieldValue(`data.animation`, {
                          hash: file.ipfs,
                          name: file.name,
                          url: file.url,
                        });
                      },
                      onRemove: () => {
                        setFieldValue(`data.animation`, {
                          hash: "",
                          name: "",
                          url: "",
                        });
                      },
                      touched: touched.data?.animation?.hash,
                      errors: errors.data?.animation?.hash,
                      label: "Animation",
                      previewImage: true,
                      imageUrl: values.data?.animation.url,
                      filename: values.data?.animation.name,
                      modalTitle: "Select the animation",
                    },
                  ].filter(Boolean)}
                />
              </Grid>
            </Grid>

            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <Box sx={{ mt: 2 }}>
              <Button
                disableElevation
                disabled={isSubmitting}
                size="large"
                type="submit"
                variant="contained"
                color="secondary"
              >
                Send invite
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </MainCard>
  );
};

export default NewInvite;
