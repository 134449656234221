import {
  GET_MULTIMEDIA,
  ADD_MULTIMEDIA,
  MULTIMEDIA_LOADING,
  MULTIMEDIA_NOT_LOADING,
  UPDATE_MULTIMEDIA_FILE,
  DELETE_MULTIMEDIA,
} from "../actions/types";

const initialState = {
  files: [],
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MULTIMEDIA:
      return {
        ...state,
        files: action.payload,
        loading: false,
      };
    case ADD_MULTIMEDIA:
      return {
        ...state,
        files: [...state.files, action.payload.multimedia],
        loading: false,
      };
    case UPDATE_MULTIMEDIA_FILE:
      return {
        ...state,
        files: state.files.map((x) =>
          x._id === action.payload.id ? { ...x, url: action.payload.url } : x
        ),
      };
    case DELETE_MULTIMEDIA:
      return {
        ...state,
        files: state.files.filter((x) => x._id !== action.payload.id),
        loading: false,
      };
    case MULTIMEDIA_LOADING:
      return {
        ...state,
        loading: true,
      };
    case MULTIMEDIA_NOT_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
