import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { Card, CardContent, Grid, IconButton } from "@mui/material";
import FormikControl from "../../forms/FormikControl";
import { DeleteOutline } from "@mui/icons-material";

const TokenAttributeForm = ({ inputs, onRemove }) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        backgroundColor: "transparent",
        borderColor: theme.palette.secondary.main,
      }}
    >
      <CardContent sx={{ padding: "0 !important" }}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <FormikControl
              control="select"
              label="Type"
              options={[
                { id: "string", label: "String" },
                { id: "number", label: "Number" },
              ]}
              formControlSx={{ ...theme.typography.formInput }}
              {...inputs.type}
            />
          </Grid>
          <Grid item xs={4}>
            <FormikControl
              control="input"
              type="text"
              label="Key"
              formControlSx={{ ...theme.typography.formInput }}
              {...inputs.key}
            />
          </Grid>
          <Grid item xs={4}>
            <FormikControl
              control="input"
              type={inputs.type.value === "string" ? "text" : "number"}
              label="Value"
              formControlSx={{ ...theme.typography.formInput }}
              {...inputs.value}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton onClick={onRemove}>
              <DeleteOutline fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

TokenAttributeForm.propTypes = {
  inputs: PropTypes.object,
  onRemove: PropTypes.func,
};

export default TokenAttributeForm;
