import PropTypes from "prop-types";
import logo from "../../assets/images/logo.jpg"

const Logo = ({ width }) => {
  return <img src={logo} alt="Berry" width={width} />;
};

Logo.defaultProps = {
  width: 300
}

Logo.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default Logo;
