import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from "@mui/material";

import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch } from "react-redux";

import AnimateButton from "../../UI/extended/AnimateButton";

import { forgotPassword } from "../../../store/actions/auth";
import LoadingSpinner from '../../common/LoadingSpinner';

const ForgotPasswordForm = ({ ...others }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required."),
      })}
      onSubmit={(values, { resetForm }) => {
        dispatch(
          forgotPassword({
            email: values.email
          })
        ).then(() => {
          resetForm();
        });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit} {...others}>
          <FormControl
            fullWidth
            error={Boolean(touched.email && errors.email)}
            sx={{ ...theme.typography.authInput }}
          >
            <InputLabel htmlFor="outlined-adornment-email">
              Email Address
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-email"
              type="email"
              value={values.email}
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              label="Email Address"
              inputProps={{}}
            />
            {touched.email && errors.email && (
              <FormHelperText
                error
                id="standard-weight-helper-text-email"
              >
                {errors.email}
              </FormHelperText>
            )}
          </FormControl>

          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}

          <Box sx={{ mt: 2 }}>
            <AnimateButton>
              <Button
                disableElevation
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="secondary"
              >
                Submit
              </Button>
            </AnimateButton>
          </Box>
          {isSubmitting && <LoadingSpinner />}
        </form>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
