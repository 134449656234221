import { NetworkType } from "@airgap/beacon-sdk";
import config from "../config";

const handleNetwork = (net) => ({
  "mainnet": NetworkType.MAINNET,
  "hangzhounet": NetworkType.HANGZHOUNET,
  "ithacanet": NetworkType.ITHACANET
})[net];

// export default config.tezos.net === "mainnet"
//   ? NetworkType.MAINNET
//   : NetworkType.HANGZHOUNET;

export default handleNetwork(config.tezos.net);
