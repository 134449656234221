import * as Yup from "yup";
import { useTheme } from "@mui/material/styles";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import FormikControl from "../../components/forms/FormikControl";
import MainCard from "../../components/UI/cards/MainCard";
import { Box } from "@mui/system";
import { Button, Divider, FormHelperText, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { updateUser } from "../../store/actions/auth";
import LoadingSpinner from '../../components/common/LoadingSpinner';

const Profile = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  return (
    <MainCard title="User Profile" style={{position: "relative"}}>
      <Formik
        initialValues={{
          name: user.name,
          current_password: "",
          password: "",
          password_confirm: "",
        }}
        enableReinitialize={true}
        validationSchema={Yup.object().shape(
          {
            name: Yup.string().required("Name is required."),
            current_password: Yup.string().when("password", {
              is: (password) => password && password.length > 0,
              then: Yup.string().required("Current password is required."),
            }),
            password: Yup.string()
              .min(6, "Password must be at least 6 characters")
              .when("current_password", {
                is: (current_password) =>
                  current_password && current_password.length > 0,
                then: Yup.string().required("Password is required."),
              }),
            password_confirm: Yup.string()
              .when("password", {
                is: (password) => password && password.length > 0,
                then: Yup.string().required("Confirm your password."),
              })
              .oneOf([Yup.ref("password")], "The passwords do not match."),
          },
          [["current_password", "password"]]
        )}
        onSubmit={(values, { setSubmitting, setFieldValue }) => {
          const { name, current_password, password, password_confirm } = values;
          const data = {};

          if (name) {
            data.name = name;
          }

          if (current_password) {
            data.current_password = current_password;
          }

          if (password) {
            data.password = password;
          }

          if (password_confirm) {
            data.password_confirm = password_confirm;
          }

          const updateUserPromise = new Promise((resolve, reject) => {
            dispatch(updateUser(data, resolve, reject));
          });

          updateUserPromise
            .then(() => {
              setFieldValue("current_password", "");
              setFieldValue("password", "");
              setFieldValue("password_confirm", "");
            })
            .catch(() => {})
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container columnSpacing={2}>
              <Grid item xs={12}>
                <FormikControl
                  control="input"
                  type="text"
                  label="Name"
                  name="name"
                  formControlSx={{ ...theme.typography.formInput }}
                  value={values.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  touched={touched.name}
                  errors={errors.name}
                />
              </Grid>

              <Grid item xs={12} sx={{ my: 2 }}>
                <Divider />
              </Grid>

              <Grid item xs={12} lg={4}>
                <FormikControl
                  control="input"
                  type="password"
                  label="Current Password"
                  name="current_password"
                  formControlSx={{ ...theme.typography.formInput }}
                  value={values.current_password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  touched={touched.current_password}
                  errors={errors.current_password}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <FormikControl
                  control="input"
                  type="password"
                  label="Password"
                  name="password"
                  formControlSx={{ ...theme.typography.formInput }}
                  value={values.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  touched={touched.password}
                  errors={errors.password}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <FormikControl
                  control="input"
                  type="password"
                  label="Password confirmation"
                  name="password_confirm"
                  formControlSx={{ ...theme.typography.formInput }}
                  value={values.password_confirm}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  touched={touched.password_confirm}
                  errors={errors.password_confirm}
                />
              </Grid>
            </Grid>

            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <Box sx={{ mt: 2 }}>
              <Button
                disableElevation
                disabled={isSubmitting}
                size="large"
                type="submit"
                variant="contained"
                color="secondary"
              >
                Update
              </Button>
            </Box>

            {isSubmitting && <LoadingSpinner />}
          </form>
        )}
      </Formik>
    </MainCard>
  );
};

export default Profile;
