import { Navigate } from "react-router-dom";
import MainLayout from "../components/layout/MainLayout";

import PrivateRoute from "../components/common/routing/PrivateRoute";
import Login from "../pages/auth/Login";
import Profile from "../pages/auth/Profile";

import ForgotPassword from "../pages/password/ForgotPassword";
import ResetPassword from "../pages/password/ResetPassword";

import Invites from "../pages/admin/invites/Invites";
import NewInvite from "../pages/admin/invites/NewInvite";
import VerifyInvite from "../components/invites/VerifyInvite";

import Lotteries from "../pages/admin/lotteries/Lotteries";
import NewLottery from "../pages/admin/lotteries/NewLottery";
import Configurations from "../pages/admin/configurations/Configurations";
import Multimedia from "../pages/admin/multimedia/Multimedia";

const routes = (isAuthenticated) => [
  {
    path: "/login",
    element: !isAuthenticated ? <Login /> : <Navigate to="/admin" />,
  },
  {
    path: "/password/forgot",
    element: <ForgotPassword />,
  },
  {
    path: "/password/reset",
    element: <ResetPassword />,
  },
  {
    path: "/profile",
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <PrivateRoute component={Profile} />,
      },
    ],
  },
  {
    path: "/verify-invite",
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <VerifyInvite />,
      },
    ],
  },
  { path: "", element: <Navigate to="login" /> },
  {
    path: "/admin",
    element: <PrivateRoute component={MainLayout} admin={true} />,
    children: [
      {
        path: "invites",
        element: <Invites />,
      },
      {
        path: "new-invite",
        element: <NewInvite />,
      },
      {
        path: "lotteries",
        element: <Lotteries />,
      },
      {
        path: "new-lottery",
        element: <NewLottery />,
      },
      {
        path: "multimedia",
        element: <Multimedia />,
      },
      {
        path: "configurations",
        element: <Configurations />,
      },
      { path: "", element: <Navigate to="invites" /> },
    ],
  },
];

export default routes;
