import { combineReducers } from "redux";
import authReducer from "./auth";
import invitesReducer from "./invites";
import lotteriesReducer from "./lotteries";
import tezosReducer from "./tezos";
import snackReducer from "./snack";
import errorsReducer from "./errors";
import customizationReducer from "./customization";
import configsReducer from "./configs";
import multimediaReducer from "./multimedia";

export default combineReducers({
  auth: authReducer,
  invites: invitesReducer,
  lotteries: lotteriesReducer,
  tezos: tezosReducer,
  snack: snackReducer,
  errors: errorsReducer,
  customization: customizationReducer,
  configs: configsReducer,
  multimedia: multimediaReducer,
});
