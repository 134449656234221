import { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";

import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Box } from "@mui/material";
import { Icon } from "@iconify/react";

const TableRowMore = ({ menuItems }) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Box component={Icon} icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {menuItems.map((menuItem, i) => {
          return menuItem.type === "link" ? (
            <MenuItem
              key={i}
              component={RouterLink}
              to={menuItem.url}
              sx={{ color: "text.secondary" }}
            >
              <ListItemIcon>
                <Box component={Icon} icon={menuItem.icon} width={20} height={20} />
              </ListItemIcon>
              <ListItemText
                primary={menuItem.label}
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
          ) : (
            <MenuItem key={i} onClick={menuItem.onClick} sx={{ color: "text.secondary" }}>
              <ListItemIcon>
                <Box component={Icon} icon={menuItem.icon} width={20} height={20} />
              </ListItemIcon>
              <ListItemText
                primary={menuItem.label}
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

TableRowMore.propTypes = {
  menuItems: PropTypes.array.isRequired,
};

export default TableRowMore;
