import PropTypes from "prop-types";
import {
  FormControl,
  FormHelperText,
  // InputLabel,
  FormGroup,
  FormControlLabel,
  Checkbox as MuiCheckbox,
} from "@mui/material";

const Checkbox = (props) => {
  const { label, name, formControlSx, errors, ...rest } = props;

  return (
    <FormControl fullWidth error={Boolean(errors)} sx={{ ...formControlSx }}>
      <FormGroup>
        <FormControlLabel
          htmlFor={`input-${name}`}
          label={label}
          control={<MuiCheckbox id={`input-${name}`} {...rest} />}
        />
      </FormGroup>

      {errors && (
        <FormHelperText error id={`select-helper-${name}`}>
          {errors}
        </FormHelperText>
      )}
    </FormControl>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  formControlSx: PropTypes.object,
  errors: PropTypes.any,
};

export default Checkbox;
