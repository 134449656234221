import { Typography, Stack } from "@mui/material";

const AuthFooter = () => (
  <Stack direction="row" justifyContent="space-between">
    <Typography variant="subtitle2" target="_blank" underline="hover">
      Cannes Film Festival
    </Typography>
  </Stack>
);

export default AuthFooter;
