import axios from "axios";
import config from "../../config";
import {
  DELETE_LOTTERY,
  CREATE_LOTTERY,
  GET_ERRORS,
  GET_LOTTERIES,
  LOTTERIES_LOADING,
  LOTTERIES_NOT_LOADING,
  SHOW_SNACK,
} from "./types";

export const getLotteries = () => (dispatch) => {
  dispatch(setLotteriesLoading());

  axios
    .get(`${config.apiUrl}/lotteries`)
    .then((res) => {
      dispatch({
        type: GET_LOTTERIES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(setLotteriesNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const createLottery = (info, resolve, reject) => (dispatch) => {
  return axios
    .post(`${config.apiUrl}/lotteries`, info)
    .then((res) => {
      dispatch({
        type: CREATE_LOTTERY,
        payload: res.data,
      });

      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: "success",
          message: "Lottery created successfully.",
        },
      });

      resolve();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });

      reject();
    });
};

export const deleteLottery = (id) => (dispatch) => {
  return axios
    .delete(`${config.apiUrl}/lotteries/${id}`)
    .then((data) => {
      if (data.data?.status) {
        dispatch({
          type: DELETE_LOTTERY,
          payload: {
            id,
          },
        });

        dispatch({
          type: SHOW_SNACK,
          payload: {
            variant: "success",
            message: "Lottery deleted.",
          },
        });
      } else {
        dispatch({
          type: SHOW_SNACK,
          payload: {
            variant: "error",
            message: data.data?.error ?? "An error has occurred.",
          },
        });
      }
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const setLotteriesLoading = () => {
  return {
    type: LOTTERIES_LOADING,
  };
};

export const setLotteriesNotLoading = () => {
  return {
    type: LOTTERIES_NOT_LOADING,
  };
};
