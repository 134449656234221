import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { isMobile } from "react-device-detect";

const AcceptButton = (props) => {
  return (
    <Button
      disableElevation
      size="large"
      type="button"
      variant="contained"
      color="secondary"
      {...props}
    >
      Accept invite
    </Button>
  );
};

const AcceptInviteButton = ({ onAccept, chain, ...rest }) => {
  if (chain === "ethereum" && isMobile && !window.ethereum) {
    const url = window.location.href.replace(/^https?:\/\//, "");
    const metamaskAppDeepLink = "https://metamask.app.link/dapp/" + url;

    return (
      <a href={metamaskAppDeepLink}>
        <AcceptButton {...rest} />
      </a>
    );
  }

  return <AcceptButton onClick={onAccept} {...rest} />;
};

AcceptInviteButton.propTypes = {
  onAccept: PropTypes.func,
  chain: PropTypes.string,
};

export default AcceptInviteButton;
