import { TezosToolkit } from "@taquito/taquito";
import storage from "redux-persist/lib/storage";
import config from "../../config";
import {
  CONNECT_TEZOS_WALLET,
  CONNECT_TEZOS_WALLET_ERROR,
  DISCONNECT_TEZOS_WALLET,
  SET_TEZOS,
  SET_TEZOS_WALLET_INSTANCE,
  TEZOS_WALLET_LOADING,
  TEZOS_WALLET_NOT_LOADING,
} from "../actions/types";

const initialState = {
  wallet_instance: null,
  Tezos: new TezosToolkit(config.tezos.rpcUrl),
  user: {
    address: "",
    publicKey: "",
  },
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONNECT_TEZOS_WALLET:
      return { ...state, user: action.user, loading: false };
    case DISCONNECT_TEZOS_WALLET:
      storage.removeItem("persist:root");
      return { ...initialState, wallet_instance: state.wallet_instance };
    case CONNECT_TEZOS_WALLET_ERROR:
      return { ...state, loading: false };
    case SET_TEZOS_WALLET_INSTANCE:
      return { ...state, wallet_instance: action.wallet_instance };
    case SET_TEZOS:
      return { ...state, Tezos: action.Tezos };
    case TEZOS_WALLET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case TEZOS_WALLET_NOT_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
