import PropTypes from "prop-types";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from "@mui/material";

const Select = (props) => {
  const { label, name, options, formControlSx, touched, errors, ...rest } = props;

  return (
    <FormControl fullWidth error={Boolean(touched && errors)} sx={{ ...formControlSx }}>
      <InputLabel htmlFor={`select-${name}`}>{label}</InputLabel>
      <MuiSelect id={`select-${name}`} name={name} label={label} {...rest}>
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.id == "" ? <em>{option.label}</em> : option.label}
          </MenuItem>
        ))}
      </MuiSelect>
      {touched && errors && (
        <FormHelperText error id={`select-helper-${name}`}>
          {errors}
        </FormHelperText>
      )}
    </FormControl>
  );
};

Select.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  formControlSx: PropTypes.object,
  touched: PropTypes.bool,
  errors: PropTypes.any,
};

export default Select;
