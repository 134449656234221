export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const AUTH_LOADING = "AUTH_LOADING";
export const AUTH_NOT_LOADING = "AUTH_NOT_LOADING";
export const UPDATE_USER = "UPDATE_USER";
export const SET_LOGOUT_TIMER = "SET_LOGOUT_TIMER";

export const GET_INVITES = "GET_INVITES";
export const CREATE_INVITE = "CREATE_INVITE";
export const GET_INVITE = "GET_INVITE";
export const ACCEPT_INVITE = "ACCEPT_INVITE";
export const CANCEL_INVITE = "CANCEL_INVITE";
export const DELETE_INVITE = "DELETE_INVITE";
export const INVALID_INVITE = "INVALID_INVITE";
export const INVITES_LOADING = "INVITES_LOADING";
export const INVITES_NOT_LOADING = "INVITES_NOT_LOADING";

export const GET_LOTTERIES = "GET_LOTTERIES";
export const CREATE_LOTTERY = "CREATE_LOTTERY";
export const GET_LOTTERY = "GET_LOTTERY";
export const DELETE_LOTTERY = "DELETE_LOTTERY";
export const LOTTERIES_LOADING = "LOTTERIES_LOADING";
export const LOTTERIES_NOT_LOADING = "LOTTERIES_NOT_LOADING";

export const GET_MULTIMEDIA = "GET_MULTIMEDIA";
export const ADD_MULTIMEDIA = "ADD_MULTIMEDIA";
export const MULTIMEDIA_LOADING = "MULTIMEDIA_LOADING";
export const DELETE_MULTIMEDIA = "DELETE_MULTIMEDIA";
export const MULTIMEDIA_NOT_LOADING = "MULTIMEDIA_NOT_LOADING";
export const UPDATE_MULTIMEDIA_FILE = "UPDATE_MULTIMEDIA_FILE";

export const CONNECT_TEZOS_WALLET = "CONNECT_TEZOS_WALLET";
export const CONNECT_TEZOS_WALLET_ERROR = "CONNECT_TEZOS_WALLET_ERROR";
export const DISCONNECT_TEZOS_WALLET = "DISCONNECT_TEZOS_WALLET";
export const SET_TEZOS = "SET_TEZOS";
export const SET_TEZOS_WALLET_INSTANCE = "SET_TEZOS_WALLET_INSTANCE";
export const TEZOS_WALLET_LOADING = "TEZOS_WALLET_LOADING";
export const TEZOS_WALLET_NOT_LOADING = "TEZOS_WALLET_NOT_LOADING";

export const SHOW_SNACK = "SHOW_SNACK";
export const CLEAR_ALL_STATE = "CLEAR_ALL_STATE";

export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const MENU_OPEN = "MENU_OPEN";
export const SET_MENU = "SET_MENU";

export const GET_CONFIGS = "GET_CONFIGS";
export const GET_PUBLIC_CONFIGS = "GET_PUBLIC_CONFIGS";
export const UPDATE_CONFIGS = "UPDATE_CONFIGS";
export const UPDATE_PUBLIC_CONFIGS = "UPDATE_PUBLIC_CONFIGS";
