import {
  GET_CONFIGS,
  GET_PUBLIC_CONFIGS,
  UPDATE_CONFIGS,
  UPDATE_PUBLIC_CONFIGS,
} from "../actions/types";

const initialState = {
  publicConfigs: {
    chain: "",
  },
  configs: {
    tezos_admin_public_key: "",
    tezos_admin_private_key: "",
    tezos_contract_address: "",
    tezos_lottery_contract_address: "",

    ethereum_admin_public_key: "",
    ethereum_admin_private_key: "",
    ethereum_contract_address: "",
    ethereum_lottery_contract_address: "",

    default_invite_name: "",
    default_invite_symbol: "",
    default_invite_description: "",
    default_invite_attributes: [],

    default_invite_artifact_hash: "",
    default_invite_display_hash: "",
    default_invite_thumbnail_hash: "",
    default_invite_image_hash: "",
    default_invite_animation_hash: "",

    client_invite_url: "",
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CONFIGS:
      return {
        ...state,
        configs: action.payload,
      };
    case GET_PUBLIC_CONFIGS:
      return {
        ...state,
        publicConfigs: action.payload,
      };
    case UPDATE_CONFIGS:
      return {
        ...state,
        configs: {
          ...state.configs,
          ...action.payload.reduce((data, config) => {
            data[config.key] = config.value;
            return data;
          }, {}),
        },
      };
    case UPDATE_PUBLIC_CONFIGS:
      return {
        ...state,
        publicConfigs: {
          ...state.publicConfigs,
          ...action.payload.reduce((data, config) => {
            data[config.key] = config.value;
            return data;
          }, {}),
        },
      };
    default:
      return state;
  }
};
