import { Networks } from "kukai-embed";
import config from "../config";

const handleNetwork = (net) => ({
  "mainnet": Networks.mainnet,
  "hangzhounet": Networks.hangzhounet,
  "ithacanet": Networks.ithacanet
})[net];

// export default config.tezos.net === "mainnet"
//   ? Networks.mainnet
//   : Networks.hangzhounet;

export default handleNetwork(config.tezos.net);