import PropTypes from "prop-types";
import { Button, FormControl, FormHelperText, FormLabel, Grid } from "@mui/material";

const FileUpload = (props) => {
  const {
    label,
    name,
    formControlSx,
    touched,
    errors,
    required,
    buttonLabel,
    removeLabel,
    previewImage,
    filename,
    imageUrl,
    onRemove,
    innerRef,
    ...rest
  } = props;

  return (
    <FormControl error={Boolean(touched && errors)} sx={{ ...formControlSx }}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <FormLabel required={required}>{label}</FormLabel>
        </Grid>
        <Grid item>
          <input type="file" style={{ display: "none" }} ref={innerRef} id={name} {...rest} />
          <label htmlFor={name}>
            <Button variant="text" color="secondary" component="span">
              {buttonLabel ?? "Upload"}
            </Button>
          </label>
        </Grid>

        {(filename || imageUrl) && (
          <Grid item>
            <Grid container spacing={2} alignItems="center">
              {filename && <Grid item>{filename}</Grid>}
              <Grid item>
                <Button variant="text" color="error" onClick={onRemove}>
                  {removeLabel ?? "Remove"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}

        {previewImage && (
          <Grid item>{imageUrl && <img src={imageUrl} width="auto" height={120} />}</Grid>
        )}
      </Grid>
      {touched && errors && (
        <FormHelperText error id={`input-helper-${name}`}>
          {errors}
        </FormHelperText>
      )}
    </FormControl>
  );
};

FileUpload.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  formControlSx: PropTypes.object,
  touched: PropTypes.bool,
  errors: PropTypes.any,
  required: PropTypes.bool,
  buttonLabel: PropTypes.string,
  removeLabel: PropTypes.string,
  previewImage: PropTypes.bool,
  filename: PropTypes.string,
  imageUrl: PropTypes.string,
  onRemove: PropTypes.func,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
};

export default FileUpload;
