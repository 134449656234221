import axios from "axios";
import config from "../../config";

import {
  GET_MULTIMEDIA,
  ADD_MULTIMEDIA,
  MULTIMEDIA_LOADING,
  MULTIMEDIA_NOT_LOADING,
  GET_ERRORS,
  SHOW_SNACK,
  UPDATE_MULTIMEDIA_FILE,
  DELETE_MULTIMEDIA,
} from "./types";

export const getFiles = () => (dispatch) => {
  dispatch(setMultimediaLoading());

  axios
    .get(`${config.apiUrl}/multimedia`)
    .then(async (res) => {
      const files = res.data;

      dispatch({
        type: GET_MULTIMEDIA,
        payload: files.map((file) => {
          if (file.path) {
            file.url = `${config.apiUrl}/${file.path}`;
          }

          return file;
        }),
      });

      // if (Array.isArray(files)) {
      //   for (let i = 0; i < files.length; i++) {
      //     const file = files[i];

      //     await dispatch(getFileContents(file._id));
      //   }
      // }

      // dispatch(setMultimediaNotLoading());
    })
    .catch((err) => {
      dispatch(setMultimediaNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response?.data ?? "An error has occurred.",
      });
    });
};

export const addFile = (info, resolve, reject) => (dispatch) => {
  dispatch(setMultimediaLoading());
  const reqConfig = { headers: { "Content-Type": "multipart/form-data" } };

  return axios
    .post(`${config.apiUrl}/multimedia`, info, reqConfig)
    .then(async (res) => {
      console.log("res data", res.data.multimedia);
      dispatch({
        type: ADD_MULTIMEDIA,
        payload: res.data,
      });

      // await dispatch(getFileContents(res.data.multimedia._id));

      // dispatch(setMultimediaNotLoading());

      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: "success",
          message: "File uploaded successfully.",
        },
      });

      resolve();
    })
    .catch((err) => {
      dispatch(setMultimediaNotLoading());
      console.log(err);
      dispatch({
        type: GET_ERRORS,
        payload: err.response?.data ?? "An error has occurred.",
      });

      reject();
    });
};

export const getFileContents = (id) => (dispatch) => {
  dispatch(setMultimediaLoading());
  const options = { responseType: "blob" };

  return axios
    .get(`${config.apiUrl}/multimedia/${id}/file`, options)
    .then((res) => {
      dispatch({
        type: UPDATE_MULTIMEDIA_FILE,
        payload: {
          id: id,
          url: URL.createObjectURL(res.data),
        },
      });
    })
    .catch((err) => {
      dispatch(setMultimediaNotLoading());
      console.log(err);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const deleteFile = (id) => (dispatch) => {
  dispatch(setMultimediaLoading());

  return axios
    .delete(`${config.apiUrl}/multimedia/${id}`)
    .then((data) => {
      if (data.data?.status) {
        dispatch({
          type: DELETE_MULTIMEDIA,
          payload: {
            id,
          },
        });

        dispatch({
          type: SHOW_SNACK,
          payload: {
            variant: "success",
            message: "File deleted.",
          },
        });
      } else {
        dispatch(setMultimediaNotLoading());
        dispatch({
          type: SHOW_SNACK,
          payload: {
            variant: "error",
            message: data.data?.error ?? "An error has occurred.",
          },
        });
      }
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const setMultimediaLoading = () => {
  return {
    type: MULTIMEDIA_LOADING,
  };
};

export const setMultimediaNotLoading = () => {
  return {
    type: MULTIMEDIA_NOT_LOADING,
  };
};
