import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { Avatar, Box, ButtonBase } from "@mui/material";
import ProfileSection from "./ProfileSection";
import LogoSection from "./LogoSection";
import { IconMenu2 } from "@tabler/icons";

const Header = ({ handleLeftDrawerToggle, admin }) => {
  const theme = useTheme();
  const auth = useSelector((state) => state.auth);

  return (
    <>
      <Box
        sx={{
          width: 228,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box component="span" sx={{ flexGrow: 1 }}>
          <LogoSection width={100} />
        </Box>
        {admin && (
          <ButtonBase sx={{ borderRadius: "12px", overflow: "hidden" }}>
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                transition: "all .2s ease-in-out",
                background: theme.palette.secondary.light,
                color: theme.palette.secondary.dark,
                "&:hover": {
                  background: theme.palette.secondary.dark,
                  color: theme.palette.secondary.light,
                },
              }}
              onClick={handleLeftDrawerToggle}
              color="inherit"
            >
              <IconMenu2 stroke={1.5} size="1.3rem" />
            </Avatar>
          </ButtonBase>
        )}
      </Box>

      <Box sx={{ flexGrow: 1 }} />
      {auth.isAuthenticated && <ProfileSection />}
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
  admin: PropTypes.bool
};

export default Header;
