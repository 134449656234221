import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";

import { ethers } from "ethers";
import { char2Bytes } from "@taquito/utils";
import { SigningType } from "@airgap/beacon-sdk";
import { isMobile } from "react-device-detect";

import {
  acceptInvite,
  resendInvitePDF,
  verifyInvite,
} from "../../../store/actions/invites";

import isEmpty from "../../../utils/isEmpty";

import MainCard from "../../UI/cards/MainCard";
import FormikControl from "../../forms/FormikControl";
import LoadingSpinner from "../../common/LoadingSpinner";
import AcceptInviteButton from "./AcceptInviteButton";
import { connectWallet, disconnectWallet } from "../../../store/actions/tezos";
import { KukaiEmbed } from "kukai-embed";
import config from "../../../config";
import kukaiEmbedNetworkType from "../../../utils/kukaiEmbedNetworkType";

const VerifyInvite = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const params = new URLSearchParams(useLocation().search);

  const { user, Tezos, wallet_instance } = useSelector((state) => state.tezos);
  const { invite = {}, valid, loading } = useSelector((state) => state.invites);
  const [sign, setSign] = useState(false);

  const chain = invite?.chain;

  const email = params.get("email");
  const uuid = params.get("uuid");

  const [embed, setEmbed] = useState(null);

  if (!email || !uuid) {
    return "Invalid data";
  }

  const tezosClient = config.tezos.clientInterface;

  const checkIfEthereumWalletIsConnected = async () => {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });

      if (accounts.length > 0) {
        return;
      }

      if (isMobile) {
        await connectEthereumWallet();
      }
    }
  };

  const connectEthereumWallet = async () => {
    if (!window.ethereum) {
      alert("No crypto wallet found. Please install it.");
      return;
    }

    await window.ethereum.request({
      method: "eth_requestAccounts",
    });
  };

  useEffect(() => {
    dispatch(
      verifyInvite({
        email,
        uuid,
      })
    );
  }, []);

  useEffect(async () => {
    if (!embed) {
      const embed = new KukaiEmbed({
        net: kukaiEmbedNetworkType,
        icon: false,
      });

      await embed.init();

      if (embed.user) {
        await embed.logout();
      }

      setEmbed(embed);
    }
  }, []);

  useEffect(() => {
    if (chain === "ethereum") {
      checkIfEthereumWalletIsConnected();
    }
  }, [invite]);

  useEffect(async () => {
    if (sign) {
      setSign(false);

      const input = `Cannes invite code: ${invite.nonce}`;

      if (chain === "ethereum") {
        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();

          const message = input;

          const signature = await signer.signMessage(message);
          const address = await signer.getAddress();

          dispatch(
            acceptInvite({
              email,
              uuid,
              nonce: invite.nonce,
              signature,
              address,
              message,
            })
          );
        } catch (err) {
          console.log(err.message);
        }
      } else if (chain === "tezos") {
        if (tezosClient === "kukaiembed") {
          try {
            const userInfo = embed.user;

            const address = userInfo.pkh;
            const pk = userInfo.pk;

            // const { message, signature } = await embed.authenticate("", input);

            const bytes = char2Bytes(` ${input}`);
            const payloadBytes =
              "05" + "01" + char2Bytes(bytes.length.toString()) + bytes;

            const message = payloadBytes;
            const signature = await embed.signExpr(message);

            dispatch(
              acceptInvite({
                email,
                uuid,
                nonce: invite.nonce,
                signature,
                address,
                pk,
                message,
              })
            );
          } catch (err) {
            console.log(err);
          } finally {
            if (embed.user) {
              await embed.logout();
            }
          }
        } else if (tezosClient === "beacon" && user.address) {
          try {
            const address = user.address;
            const publicKey = user.publicKey;

            const formattedInput = [" Tezos Signed Message:", input].join(" ");

            const bytes = char2Bytes(formattedInput);
            const payloadBytes =
              "05" + "01" + char2Bytes(bytes.length.toString()) + bytes;

            const payload = {
              signingType: SigningType.RAW,
              payload: payloadBytes,
              sourceAddress: address,
            };

            const signedPayload =
              await wallet_instance.client.requestSignPayload(payload);

            const message = payloadBytes;
            const signature = signedPayload.signature;

            dispatch(disconnectWallet(wallet_instance));
            dispatch(
              acceptInvite({
                email,
                uuid,
                nonce: invite.nonce,
                signature,
                address,
                pk: publicKey,
                message,
              })
            );
          } catch (err) {
            console.log(err.message);

            dispatch(disconnectWallet(wallet_instance));
          }
        }
      }
    }
  }, [chain, sign, user.address, dispatch]);

  const connectTezosWallet = async () => {
    return dispatch(connectWallet({ Tezos, wallet_instance }));
  };

  const acceptInviteHandler = async () => {
    if (chain === "ethereum") {
      if (!window.ethereum) {
        alert("No crypto wallet found. Please install it.");
        return;
      }

      await window.ethereum.send("eth_requestAccounts");

      setSign(true);
    } else if (chain === "tezos") {
      if (tezosClient === "kukaiembed") {
        try {
          if (embed.user) {
            await embed.logout();
          }

          await embed.login();

          setSign(true);
        } catch (err) {
          console.log({ err });
        }
      } else if (tezosClient === "beacon") {
        if (user.address === "") {
          await connectTezosWallet();
        }

        setSign(true);
      }
    }
  };

  const resendEmailHandler = async () => {
    dispatch(
      resendInvitePDF({
        email,
        uuid,
      })
    );
  };

  if (!valid) {
    return <Typography variant="h5">Invalid invite.</Typography>;
  }

  if (loading || isEmpty(invite)) {
    return <LoadingSpinner />;
  }

  return (
    <MainCard title="Invite">
      {!invite.accepted_at ? (
        <>
          <FormikControl
            control="input"
            type="text"
            disabled
            name="Invite code"
            formControlSx={{ ...theme.typography.formInput }}
            value={invite.nonce}
          />
          <Box sx={{ mt: 2 }}>
            <AcceptInviteButton
              onAccept={acceptInviteHandler}
              disabled={
                chain === "tezos" && tezosClient === "kukaiembed" && !embed
              }
            />
          </Box>
        </>
      ) : invite.minted_at ? (
        <Button
          onClick={resendEmailHandler}
          disableElevation
          size="large"
          type="button"
          variant="contained"
          color="secondary"
        >
          Resend email
        </Button>
      ) : (
        <Typography variant="h4">
          Waiting for the invitation to be processed.
        </Typography>
      )}
    </MainCard>
  );
};

export default VerifyInvite;
