import {
  CREATE_LOTTERY,
  GET_LOTTERIES,
  GET_LOTTERY,
  DELETE_LOTTERY,
  LOTTERIES_LOADING,
  LOTTERIES_NOT_LOADING,
} from "../actions/types";

const initialState = {
  lotteries: [],
  lottery: null,
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LOTTERIES:
      return {
        ...state,
        lotteries: action.payload,
        loading: false,
      };
    case CREATE_LOTTERY:
      return {
        ...state,
        lotteries: [action.payload.lottery, ...state.lotteries],
        loading: false,
      };
    case GET_LOTTERY:
      return {
        ...state,
        lottery: action.payload,
        loading: false,
      };
    case DELETE_LOTTERY:
      return {
        ...state,
        lotteries: state.lotteries.filter((x) => x._id !== action.payload.id),
      };
    case LOTTERIES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case LOTTERIES_NOT_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
