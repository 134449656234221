import PropTypes from "prop-types";
import {
  FormControl,
  FormHelperText,
  // InputLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
} from "@mui/material";
import { Box } from "@mui/system";

const CheckboxGroup = (props) => {
  const {
    label,
    name,
    options,
    formControlSx,
    errors,
    value,
    parentCheckbox,
    onParentChange,
    ...rest
  } = props;

  const allChecked = options.every((option) => value.includes(option.key));
  const someChecked = !allChecked && options.some((option) => value.includes(option.key));

  const handleParentChange = () => {
    onParentChange();
  };

  return (
    <FormControl fullWidth error={Boolean(errors)} sx={{ ...formControlSx }}>
      {!parentCheckbox && <FormLabel>{label}</FormLabel>}
      <FormGroup>
        {parentCheckbox && (
          <FormControlLabel
            label={label}
            control={
              <Checkbox
                indeterminate={someChecked}
                checked={allChecked}
                onChange={handleParentChange}
              />
            }
          />
        )}
        <Box sx={{ display: "flex", flexDirection: "column", ml: parentCheckbox && 3 }}>
          {options.map((option, i) => (
            <FormControlLabel
              key={i}
              control={
                <Checkbox value={option.key} checked={value.includes(option.key)} {...rest} />
              }
              label={option.label}
            />
          ))}
        </Box>
      </FormGroup>

      {errors && (
        <FormHelperText error id={`select-helper-${name}`}>
          {errors}
        </FormHelperText>
      )}
    </FormControl>
  );
};

CheckboxGroup.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  parentCheckbox: PropTypes.bool,
  onParentChange: PropTypes.func,
  formControlSx: PropTypes.object,
  errors: PropTypes.any,
};

export default CheckboxGroup;
