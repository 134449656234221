import {
  GET_INVITES,
  CREATE_INVITE,
  GET_INVITE,
  INVITES_LOADING,
  INVITES_NOT_LOADING,
  CANCEL_INVITE,
  DELETE_INVITE,
  ACCEPT_INVITE,
  INVALID_INVITE,
} from "../actions/types";

const initialState = {
  invites: [],
  invite: null,
  valid: true,
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_INVITES:
      return {
        ...state,
        invites: action.payload,
        valid: true,
        loading: false,
      };
    case CREATE_INVITE:
      return {
        ...state,
        invites: [action.payload.invite, ...state.invites],
        valid: true,
        loading: false,
      };
    case GET_INVITE:
      return {
        ...state,
        invite: action.payload,
        valid: true,
        loading: false,
      };
    case ACCEPT_INVITE:
      return {
        ...state,
        invite: { ...state.invite, accepted_at: true },
        valid: true,
        loading: false,
      };
    case CANCEL_INVITE:
      return {
        ...state,
        invites: state.invites.map((x) =>
          x._id === action.payload.id ? { ...x, enabled: false } : x
        ),
        valid: true,
        loading: false,
      };
    case DELETE_INVITE:
      return {
        ...state,
        invites: state.invites.filter((x) => x._id !== action.payload.id),
      };
    case INVALID_INVITE:
      return {
        ...state,
        valid: false,
      };
    case INVITES_LOADING:
      return {
        ...state,
        valid: true,
        loading: true,
      };
    case INVITES_NOT_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
