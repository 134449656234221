import { useSelector, useDispatch } from "react-redux";
import { Grid, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { getInvites } from "../../../store/actions/invites";
import InvitesList from "../../../components/admin/invites/InvitesList";
import LoadingSpinner from "../../../components/common/LoadingSpinner";

const Invites = () => {
  const dispatch = useDispatch();
  const { invites, loading } = useSelector((state) => state.invites);

  useEffect(() => {
    dispatch(getInvites());
  }, [dispatch]);

  return (
    <Grid container>
      <Grid item xs={8}>
        <Typography variant="h4" gutterBottom={true}>
          Invites
        </Typography>
      </Grid>

      <Grid item xs={4} style={{ textAlign: "right" }}>
        <Link to="/admin/new-invite" style={{ color: "inherit" }}>
          <Button>New Invite</Button>
        </Link>
      </Grid>

      <Grid item xs={12} mt={1}>
        <InvitesList
          invites={invites.map((x) => {
            return { ...x, accepted: !!x.accepted_at };
          })}
        />
      </Grid>
      {loading && <LoadingSpinner />}
    </Grid>
  );
};

export default Invites;
