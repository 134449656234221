import PropTypes from "prop-types";
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio } from "@mui/material";
import React from "react";

const RadioGroup = (props) => {
  const { label, name, options, formControlSx, errors, value, ...rest } = props;

  return (
    <FormControl fullWidth error={Boolean(errors)} sx={{ ...formControlSx }}>
      <FormLabel>{label}</FormLabel>
      {options.map((option) => {
        return (
          <FormControlLabel
            key={option.value}
            name={name}
            control={<Radio />}
            label={option.label}
            value={option.value}
            checked={option.value === value}
            {...rest}
          />
        );
      })}

      {errors && (
        <FormHelperText error id={`select-helper-${name}`}>
          {errors}
        </FormHelperText>
      )}
    </FormControl>
  );
};

RadioGroup.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  options: PropTypes.array.isRequired,
  formControlSx: PropTypes.object,
  errors: PropTypes.any,
};

export default RadioGroup;
