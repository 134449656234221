import { TezosToolkit } from "@taquito/taquito";

import config from "../../config";

import {
  CONNECT_TEZOS_WALLET,
  CONNECT_TEZOS_WALLET_ERROR,
  DISCONNECT_TEZOS_WALLET,
  SET_TEZOS,
  SET_TEZOS_WALLET_INSTANCE,
} from "./types";
import beaconNetworkType from "../../utils/beaconNetworkType";

export const connectWallet =
  ({ wallet_instance, Tezos }) =>
  async (dispatch) => {
    try {
      const payload = {};

      Tezos.setWalletProvider(wallet_instance);

      const activeAccount = await wallet_instance.client.getActiveAccount();
      if (!activeAccount) {
        await wallet_instance.requestPermissions({
          network: {
            type: beaconNetworkType,
            rpcUrl: config.tezos.rpcUrl,
          },
        });
      }

      const userAddress = await wallet_instance.getPKH();
      const account = await wallet_instance.client.getActiveAccount();
      const publicKey = account.publicKey;

      payload.user = {
        address: userAddress,
        publicKey,
      };
      dispatch(_walletConfig(payload.user));
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONNECT_TEZOS_WALLET_ERROR,
      });
    }
  };

export const _walletConfig = (user) => {
  return {
    type: CONNECT_TEZOS_WALLET,
    user,
  };
};

export const disconnectWallet =
  ({ wallet_instance }) =>
  async (dispatch) => {
    setTezos(new TezosToolkit(config.tezos.rpcUrl));

    dispatch({
      type: DISCONNECT_TEZOS_WALLET,
    });

    if (wallet_instance) {
      await wallet_instance.client.removeAllAccounts();
      await wallet_instance.client.removeAllPeers();
      await wallet_instance.client.destroy();
    }
  };

export const setWallet = (wallet_instance) => {
  return {
    type: SET_TEZOS_WALLET_INSTANCE,
    wallet_instance: wallet_instance,
  };
};

export const setTezos = (Tezos) => {
  return {
    type: SET_TEZOS,
    Tezos,
  };
};
