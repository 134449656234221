import {
  SET_CURRENT_USER,
  GET_ERRORS,
  CLEAR_ERRORS,
  AUTH_LOADING,
  AUTH_NOT_LOADING,
  UPDATE_USER,
  SHOW_SNACK,
  SET_LOGOUT_TIMER,
} from "./types";
import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";
import isEmpty from "../../utils/isEmpty";
import config from "../../config";

export const loginUser = (loginData, resolve, reject) => (dispatch) => {
  dispatch(clearErrors());
  dispatch(authLoading());

  return axios
    .post(`${config.apiUrl}/login`, loginData)
    .then((res) => {
      // Save token to sessionStorage
      const { token, expires } = res.data;
      if (!isEmpty(token)) {
        localStorage.setItem("jwtToken", token);
        localStorage.setItem(
          "jwtExpires",
          new Date().getTime() + expires * 1000
        );
      }

      const logoutTimer = setTimeout(() => {
        dispatch(logoutUser());
      }, expires * 1000);

      dispatch(setLogoutTimer(logoutTimer));

      // Set token to auth header
      setAuthToken(token);

      dispatch(getUserInfo());

      if (resolve) {
        resolve(res.data);
      }
    })
    .catch((err) => {
      dispatch(authNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: {
          error:
            (err &&
              err.response &&
              err.response &&
              err.response.data &&
              err.response.data.error) ||
            "Incorrect e-mail or password.",
        },
      });

      if (reject) {
        reject({
          error: err,
          message: "Incorrect e-mail or password.",
        });
      }
    });
};

export const getUserInfo = () => (dispatch) => {
  dispatch(clearErrors());

  axios
    .get(`${config.apiUrl}/me`)
    .then((res) => {
      dispatch(setCurrentUser(res.data));
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const updateUser = (info, resolve, reject) => (dispatch) => {
  return axios
    .put(`${config.apiUrl}/user`, {
      name: info.name,
      current_password: info.current_password,
      password: info.password,
      password_confirm: info.password_confirm,
    })
    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });

      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: "success",
          message: "Profile updated successfully.",
        },
      });

      resolve(res.data);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });

      reject(err);
    });
};

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("jwtExpires");

  setAuthToken(false);
  dispatch(setLogoutTimer(null));
  dispatch(setCurrentUser({}));

  parent.postMessage(
    {
      target: "tickets",
      data: {
        error: "Your session has ended. Please refresh.",
      },
    },
    "*"
  );
};

export const forgotPassword = (info) => (dispatch) => {
  return axios
    .post(`${config.apiUrl}/forgot-password`, info)
    .then(() => {
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: "success",
          message: "Recovery email sent.",
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const resetPassword = (info, resolve, reject) => (dispatch) => {
  return axios
    .post(`${config.apiUrl}/reset-password`, info)
    .then(() => {
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: "success",
          message: "Password successfully updated.",
        },
      });

      resolve();
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });

      reject();
    });
};

export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

export const authLoading = () => {
  return {
    type: AUTH_LOADING,
  };
};

export const authNotLoading = () => {
  return {
    type: AUTH_NOT_LOADING,
  };
};

export const setLogoutTimer = (logoutTimer) => {
  return {
    type: SET_LOGOUT_TIMER,
    payload: logoutTimer,
  };
};
