import { IconTicket, IconDots, IconSettings, IconFiles } from "@tabler/icons";

const icons = { IconTicket, IconDots, IconSettings, IconFiles };

const administration = {
  id: "administration",
  type: "group",
  url: "/admin",
  children: [
    {
      id: "invites",
      title: "Invites",
      type: "item",
      url: "/admin/invites",
      icon: icons.IconTicket,
      breadcrumbs: false,
    },
    {
      id: "lotteries",
      title: "Lotteries",
      type: "item",
      url: "/admin/lotteries",
      icon: icons.IconDots,
      breadcrumbs: false,
    },
    {
      id: "multimedia",
      title: "Multimedia",
      type: "item",
      url: "/admin/multimedia",
      icon: icons.IconFiles,
      breadcrumbs: false,
    },
    {
      id: "configurations",
      title: "Configurations",
      type: "item",
      url: "/admin/configurations",
      icon: icons.IconSettings,
      breadcrumbs: false,
    },
  ],
};

export default administration;
