import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { Formik } from "formik";
import * as Yup from "yup";

import { getConfigs, updateConfigs } from "../../../store/actions/configs";
import MainCard from "../../../components/UI/cards/MainCard";
import FormikControl from "../../../components/forms/FormikControl";
import { Box } from "@mui/system";
import {
  Button,
  Divider,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import TokenForm from "../../../components/admin/lotteries/TokenForm";
import { getFiles } from "../../../store/actions/multimedia";

const Configurations = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { configs } = useSelector((state) => state.configs);
  const { files } = useSelector((state) => state.multimedia);

  useEffect(() => {
    dispatch(getConfigs());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getFiles());
  }, []);

  useEffect(() => {
    if (configs?.default_invite_artifact_hash) {
      const artifact = files.find(
        (x) => x.ipfs === configs?.default_invite_artifact_hash
      );

      setDefaultInviteArtifact({
        hash: configs?.default_invite_artifact_hash,
        url: artifact && artifact.url,
        name: artifact && artifact.name,
      });
    }

    if (configs?.default_invite_display_hash) {
      const display = files.find(
        (x) => x.ipfs === configs?.default_invite_display_hash
      );

      setDefaultInviteDisplay({
        hash: configs?.default_invite_display_hash,
        url: display && display.url,
        name: display && display.name,
      });
    }

    if (configs?.default_invite_thumbnail_hash) {
      const thumbnail = files.find(
        (x) => x.ipfs === configs?.default_invite_thumbnail_hash
      );

      setDefaultInviteThumbnail({
        hash: configs?.default_invite_thumbnail_hash,
        url: thumbnail && thumbnail.url,
        name: thumbnail && thumbnail.name,
      });
    }

    if (configs?.default_invite_image_hash) {
      const image = files.find(
        (x) => x.ipfs === configs?.default_invite_image_hash
      );

      setDefaultInviteImage({
        hash: configs?.default_invite_image_hash,
        url: image && image.url,
        name: image && image.name,
      });
    }

    // const animation = files.find((x) => x._id === configs?.default_invite_animation_id);

    // if (animation) {
    //   setDefaultInviteAnimation({
    //     url: animation.url,
    //     name: animation.name,
    //   });
    // }

    if (configs?.default_invite_animation_hash) {
      const animation = files.find(
        (x) => x.ipfs === configs?.default_invite_animation_hash
      );

      setDefaultInviteAnimation({
        hash: configs?.default_invite_animation_hash,
        url: animation && animation.url,
        name: animation && animation.name,
      });
    }
  }, [files, configs]);

  const [defaultInviteArtifact, setDefaultInviteArtifact] = useState({
    name: "",
    url: "",
  });

  const [defaultInviteDisplay, setDefaultInviteDisplay] = useState({
    name: "",
    url: "",
  });

  const [defaultInviteThumbnail, setDefaultInviteThumbnail] = useState({
    name: "",
    url: "",
  });

  const [defaultInviteImage, setDefaultInviteImage] = useState({
    name: "",
    url: "",
  });

  const [defaultInviteAnimation, setDefaultInviteAnimation] = useState({
    name: "",
    url: "",
  });

  const chainOptions = [
    { id: "tezos", label: "Tezos" },
    { id: "ethereum", label: "Ethereum" },
  ];

  return (
    <MainCard title="Configurations">
      <Formik
        initialValues={{
          chain: configs?.chain ?? "",

          tezos_admin_public_key: configs?.tezos_admin_public_key ?? "",
          tezos_admin_private_key: configs?.tezos_admin_private_key ?? "",
          tezos_contract_address: configs?.tezos_contract_address ?? "",
          tezos_lottery_contract_address:
            configs?.tezos_lottery_contract_address ?? "",

          ethereum_admin_public_key: configs?.ethereum_admin_public_key ?? "",
          ethereum_admin_private_key: configs?.ethereum_admin_private_key ?? "",
          ethereum_contract_address: configs?.ethereum_contract_address ?? "",
          ethereum_lottery_contract_address:
            configs?.ethereum_lottery_contract_address ?? "",

          client_invite_url: configs?.client_invite_url ?? "",

          default_invite_name: configs?.default_invite_name ?? "",
          default_invite_symbol: configs?.default_invite_symbol ?? "",
          default_invite_description: configs?.default_invite_description ?? "",
          default_invite_attributes: configs?.default_invite_attributes ?? [],

          default_invite_artifact: {
            hash: configs?.default_invite_artifact_hash ?? "",
            name: defaultInviteArtifact.name,
            url: defaultInviteArtifact.url,
          },
          default_invite_display: {
            hash: configs?.default_invite_display_hash ?? "",
            name: defaultInviteDisplay.name,
            url: defaultInviteDisplay.url,
          },
          default_invite_thumbnail: {
            hash: configs?.default_invite_thumbnail_hash ?? "",
            name: defaultInviteThumbnail.name,
            url: defaultInviteThumbnail.url,
          },
          default_invite_image: {
            hash: configs?.default_invite_image_hash ?? "",
            name: defaultInviteImage.name,
            url: defaultInviteImage.url,
          },
          default_invite_animation: {
            hash: configs?.default_invite_animation_hash ?? "",
            name: defaultInviteAnimation.name,
            url: defaultInviteAnimation.url,
          },
        }}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({})}
        onSubmit={(values, { setSubmitting }) => {
          let configs = [
            { key: "chain", value: values.chain },
            {
              key: "tezos_admin_public_key",
              value: values.tezos_admin_public_key,
            },
            {
              key: "tezos_admin_private_key",
              value: values.tezos_admin_private_key,
            },
            {
              key: "tezos_contract_address",
              value: values.tezos_contract_address,
            },
            {
              key: "tezos_lottery_contract_address",
              value: values.tezos_lottery_contract_address,
            },
            {
              key: "ethereum_admin_public_key",
              value: values.ethereum_admin_public_key,
            },
            {
              key: "ethereum_admin_private_key",
              value: values.ethereum_admin_private_key,
            },
            {
              key: "ethereum_contract_address",
              value: values.ethereum_contract_address,
            },
            {
              key: "ethereum_lottery_contract_address",
              value: values.ethereum_lottery_contract_address,
            },

            {
              key: "client_invite_url",
              value: values.client_invite_url,
            },

            {
              key: "default_invite_name",
              value: values.default_invite_name,
            },
            {
              key: "default_invite_symbol",
              value: values.default_invite_symbol,
            },
            {
              key: "default_invite_description",
              value: values.default_invite_description,
            },
            {
              key: "default_invite_attributes",
              value: values.default_invite_attributes,
            },
            {
              key: "default_invite_artifact_hash",
              value: values.default_invite_artifact.hash,
            },
            {
              key: "default_invite_display_hash",
              value: values.default_invite_display.hash,
            },
            {
              key: "default_invite_thumbnail_hash",
              value: values.default_invite_thumbnail.hash,
            },
            {
              key: "default_invite_image_hash",
              value: values.default_invite_image.hash,
            },
            {
              key: "default_invite_animation_hash",
              value: values.default_invite_animation.hash,
            },
          ];

          new Promise((resolve, reject) => {
            dispatch(updateConfigs({ configs }, resolve, reject));
          })
            .then(() => {})
            .catch(() => {})
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12}>
                <FormikControl
                  control="input"
                  type="text"
                  label="Client invite URL"
                  name="client_invite_url"
                  formControlSx={{ ...theme.typography.formInput }}
                  value={values.client_invite_url}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  touched={touched.client_invite_url}
                  errors={errors.client_invite_url}
                />
              </Grid>

              <Grid item xs={12} sx={{ my: 2 }}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  Blockchain
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <FormikControl
                  control="select"
                  label="Current Blockchain"
                  name="chain"
                  value={values.chain}
                  options={chainOptions}
                  formControlSx={{ ...theme.typography.formInput }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  touched={touched.chain}
                  errors={errors.chain}
                />
              </Grid>

              {values.chain === "tezos" && (
                <>
                  <Grid item xs={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Admin Public Key"
                      name="tezos_admin_public_key"
                      formControlSx={{ ...theme.typography.formInput }}
                      value={values.tezos_admin_public_key}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      touched={touched.tezos_admin_public_key}
                      errors={errors.tezos_admin_public_key}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Admin Private Key"
                      name="tezos_admin_private_key"
                      formControlSx={{ ...theme.typography.formInput }}
                      value={values.tezos_admin_private_key}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      touched={touched.tezos_admin_private_key}
                      errors={errors.tezos_admin_private_key}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Contract Address"
                      name="tezos_contract_address"
                      formControlSx={{ ...theme.typography.formInput }}
                      value={values.tezos_contract_address}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      touched={touched.tezos_contract_address}
                      errors={errors.tezos_contract_address}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Lottery Contract Address"
                      name="tezos_lottery_contract_address"
                      formControlSx={{ ...theme.typography.formInput }}
                      value={values.tezos_lottery_contract_address}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      touched={touched.tezos_lottery_contract_address}
                      errors={errors.tezos_lottery_contract_address}
                    />
                  </Grid>
                </>
              )}

              {values.chain === "ethereum" && (
                <>
                  <Grid item xs={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Admin Public Key"
                      name="ethereum_admin_public_key"
                      formControlSx={{ ...theme.typography.formInput }}
                      value={values.ethereum_admin_public_key}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      touched={touched.ethereum_admin_public_key}
                      errors={errors.ethereum_admin_public_key}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Admin Private Key"
                      name="ethereum_admin_private_key"
                      formControlSx={{ ...theme.typography.formInput }}
                      value={values.ethereum_admin_private_key}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      touched={touched.ethereum_admin_private_key}
                      errors={errors.ethereum_admin_private_key}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Contract Address"
                      name="ethereum_contract_address"
                      formControlSx={{ ...theme.typography.formInput }}
                      value={values.ethereum_contract_address}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      touched={touched.ethereum_contract_address}
                      errors={errors.ethereum_contract_address}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Lottery Contract Address"
                      name="ethereum_lottery_contract_address"
                      formControlSx={{ ...theme.typography.formInput }}
                      value={values.ethereum_lottery_contract_address}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      touched={touched.ethereum_lottery_contract_address}
                      errors={errors.ethereum_lottery_contract_address}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12} sx={{ my: 2 }}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  Invite data
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TokenForm
                  inputs={[
                    {
                      name: "default_invite_name",
                      value: values.default_invite_name,
                      onBlur: handleBlur,
                      onChange: handleChange,
                      touched: touched.default_invite_name,
                      errors: errors.default_invite_name,
                      label: "Token name",
                    },
                    {
                      name: "default_invite_symbol",
                      value: values.default_invite_symbol,
                      onBlur: handleBlur,
                      onChange: handleChange,
                      touched: touched.default_invite_symbol,
                      errors: errors.default_invite_symbol,
                      label: "Token symbol",
                    },
                    {
                      name: "default_invite_description",
                      value: values.default_invite_description,
                      onBlur: handleBlur,
                      onChange: handleChange,
                      touched: touched.default_invite_description,
                      errors: errors.default_invite_description,
                      label: "Token description",
                    },
                  ]}
                  attributesName={`default_invite_attributes`}
                  attributes={values.default_invite_attributes.map(
                    (attribute, idx) => {
                      return {
                        type: {
                          value: attribute.type,
                          onBlur: handleBlur,
                          onChange: handleChange,
                          touched:
                            touched.default_invite_attributes?.[idx]?.type,
                          errors: errors.default_invite_attributes?.[idx]?.type,
                        },
                        key: {
                          value: attribute.key,
                          onBlur: handleBlur,
                          onChange: handleChange,
                          touched:
                            touched.default_invite_attributes?.[idx]?.key,
                          errors: errors.default_invite_attributes?.[idx]?.key,
                        },
                        value: {
                          value: attribute.value,
                          onBlur: handleBlur,
                          onChange: handleChange,
                          touched:
                            touched.default_invite_attributes?.[idx]?.value,
                          errors:
                            errors.default_invite_attributes?.[idx]?.value,
                        },
                      };
                    }
                  )}
                  multimedia={[
                    {
                      name: "default_invite_artifact.hash",
                      value: values.default_invite_artifact.hash,
                      onBlur: handleBlur,
                      onChange: (file) => {
                        setFieldValue("default_invite_artifact", {
                          hash: file.ipfs,
                          url: file.url,
                          name: file.name,
                        });
                      },
                      onRemove: () => {
                        setFieldValue("default_invite_artifact", {
                          hash: "",
                          url: "",
                          name: "",
                        });
                      },
                      touched: touched.default_invite_artifact?.hash,
                      errors: errors.default_invite_artifact?.hash,
                      label: "Artifact",
                      previewImage: true,
                      imageUrl: values.default_invite_artifact.url,
                      filename:
                        values.default_invite_artifact.name ??
                        values.default_invite_artifact.hash,
                      modalTitle: "Select the artifact image",
                    },
                    {
                      name: "default_invite_display.hash",
                      value: values.default_invite_display.hash,
                      onBlur: handleBlur,
                      onChange: (file) => {
                        setFieldValue("default_invite_display", {
                          hash: file.ipfs,
                          url: file.url,
                          name: file.name,
                        });
                      },
                      onRemove: () => {
                        setFieldValue("default_invite_display", {
                          hash: "",
                          url: "",
                          name: "",
                        });
                      },
                      touched: touched.default_invite_display?.hash,
                      errors: errors.default_invite_display?.hash,
                      label: "Display",
                      previewImage: true,
                      imageUrl: values.default_invite_display.url,
                      filename:
                        values.default_invite_display.name ??
                        values.default_invite_display.hash,
                      modalTitle: "Select the display image",
                    },
                    {
                      name: "default_invite_thumbnail.hash",
                      value: values.default_invite_thumbnail.hash,
                      onBlur: handleBlur,
                      onChange: (file) => {
                        setFieldValue("default_invite_thumbnail", {
                          hash: file.ipfs,
                          url: file.url,
                          name: file.name,
                        });
                      },
                      onRemove: () => {
                        setFieldValue("default_invite_thumbnail", {
                          hash: "",
                          url: "",
                          name: "",
                        });
                      },
                      touched: touched.default_invite_thumbnail?.hash,
                      errors: errors.default_invite_thumbnail?.hash,
                      label: "Thumbnail",
                      previewImage: true,
                      imageUrl: values.default_invite_thumbnail.url,
                      filename:
                        values.default_invite_thumbnail.name ??
                        values.default_invite_thumbnail.hash,
                      modalTitle: "Select the thumbnail image",
                    },
                    {
                      name: "default_invite_image.hash",
                      value: values.default_invite_image.hash,
                      onBlur: handleBlur,
                      onChange: (file) => {
                        setFieldValue("default_invite_image", {
                          hash: file.ipfs,
                          url: file.url,
                          name: file.name,
                        });
                      },
                      onRemove: () => {
                        setFieldValue("default_invite_image", {
                          hash: "",
                          url: "",
                          name: "",
                        });
                      },
                      touched: touched.default_invite_image?.hash,
                      errors: errors.default_invite_image?.hash,
                      label: "Image",
                      previewImage: true,
                      imageUrl: values.default_invite_image.url,
                      filename:
                        values.default_invite_image.name ??
                        values.default_invite_image.hash,
                      modalTitle: "Select the image",
                    },
                    {
                      name: "default_invite_animation.hash",
                      value: values.default_invite_animation.hash,
                      onBlur: handleBlur,
                      onChange: (file) => {
                        setFieldValue("default_invite_animation", {
                          hash: file.ipfs,
                          url: file.url,
                          name: file.name,
                        });
                      },
                      onRemove: () => {
                        setFieldValue("default_invite_animation", {
                          hash: "",
                          url: "",
                          name: "",
                        });
                      },
                      touched: touched.default_invite_animation?.hash,
                      errors: errors.default_invite_animation?.hash,
                      label: "Animation",
                      previewImage: true,
                      imageUrl: values.default_invite_animation.url,
                      filename:
                        values.default_invite_animation.name ??
                        values.default_invite_animation.hash,
                      modalTitle: "Select the animation",
                    },
                  ]}
                />
              </Grid>
            </Grid>

            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <Box sx={{ mt: 2 }}>
              <Button
                disableElevation
                disabled={isSubmitting}
                size="large"
                type="submit"
                variant="contained"
                color="secondary"
              >
                Update
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </MainCard>
  );
};

export default Configurations;
