const tezosNet = process.env.REACT_APP_TEZOS_NET || "mainnet";

const {
  REACT_APP_API_URL,
  REACT_APP_CHAIN,
  REACT_APP_TEZOS_MAINNET_RPC_URL,
  REACT_APP_TEZOS_HANGZHOUNET_RPC_URL,
  REACT_APP_TEZOS_ITHACANET_RPC_URL,
  REACT_APP_TEZOS_CLIENT_INTERFACE,
  REACT_APP_BACKOFFICE_URL,
} = process.env;

const handleNetRpc = (net) => ({
  "mainnet": REACT_APP_TEZOS_MAINNET_RPC_URL,
  "hangzhounet": REACT_APP_TEZOS_HANGZHOUNET_RPC_URL,
  "ithacanet": REACT_APP_TEZOS_ITHACANET_RPC_URL
})[net];

const config = {
  apiUrl: REACT_APP_API_URL,
  chain: REACT_APP_CHAIN || "ethereum",
  tezos: {
    net: tezosNet,
    // rpcUrl:
    //   tezosNet === "mainnet"
    //     ? REACT_APP_TEZOS_MAINNET_RPC_URL
    //     : REACT_APP_TEZOS_HANGZHOUNET_RPC_URL,
    rpcUrl: handleNetRpc(tezosNet),
    clientInterface: REACT_APP_TEZOS_CLIENT_INTERFACE || "kukaiembed",
  },
  backofficeUrl: REACT_APP_BACKOFFICE_URL
    ? REACT_APP_BACKOFFICE_URL.split(",").map((item) => item.trim())
    : ["http://localhost"],
};

export default config;
